import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../auth/store/auth.actions';
import * as fromApp from '../../store/app.reducer';

@Component({
  selector: 'app-create-register-account',
  templateUrl: './create-register-account.component.html',
  styleUrls: ['./create-register-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRegisterAccountComponent {
  constructor(private store: Store<fromApp.AppState>) {}

  onLogin() {
    this.store.dispatch(AuthActions.login());
  }
  onCreate() {
    this.store.dispatch(AuthActions.createAccount());
  }
}

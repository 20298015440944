<div *ngIf="loading; else loaded">
  <div style="width: 50%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
  </div>

  <div style="width: 40%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
  </div>

  <div style="width: 50%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
  </div>

  <div style="width: 60%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
  </div>
</div>
<ng-template #loaded>
  <div data-testid="service-requests" *ngIf="requests?.length; else noRequests">
    <div id="table">
      <div *ngFor="let request of requests | slice : 0 : limit">
        <div class="tr">
          <div class="td label first">Ref #</div>
          <div class="td">{{ request.ref_no }}</div>
        </div>

        <div class="tr">
          <div class="td label">Status</div>
          <div class="td">{{ request.status }}</div>
        </div>
        <div class="tr">
          <div class="td label">Created</div>
          <div class="td">{{ request.created | date }}</div>
        </div>
        <div class="tr">
          <div class="td label">Subject</div>
          <div class="td last">{{ request.subject }}</div>
        </div>
        <mat-divider *ngIf="limit > 1"></mat-divider>
      </div>
    </div>
  </div>
  <ng-template #noRequests>
    <div data-test="no-requests">
      <p>You do not have any service requests.</p>
    </div>
  </ng-template>
</ng-template>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { CustomerAddressComponent } from '../../customer/customer-address-list/customer-address/customer-address.component';
import { CustomerAddress } from '../../customer/customer.model';
import * as CustomerAddressActions from '../../customer/store/actions/customer-address.actions';
import {
  selectCustomerAddressErrorMessage,
  selectCustomerAddressLoaded,
  selectHasPrimaryAddress,
} from '../../customer/store/selectors/customer-address.selector';
import { SharedModule } from '../../shared/shared.module';
import * as fromApp from '../../store/app.reducer';
import { GarbageCollectionSchedule } from './data-access/garbage-collection.model';
import { GarbageCollectionStore } from './data-access/garbage-collection.store';
import { GarbageServicesListComponent } from './ui/garbage-services-list/garbage-services-list.component';
import { selectAppSettings } from '../../app-settings/store/app-settings.selectors';
import { BaseWidgetSettings } from '../../app-settings/app-settings.model';

@Component({
  selector: 'app-garbage-collection',
  templateUrl: './garbage-collection.component.html',
  styleUrls: ['./garbage-collection.component.scss'],
  providers: [GarbageCollectionStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, GarbageServicesListComponent],
})
export class GarbageCollectionComponent implements OnInit {
  @Input() loggedIn = true;
  @Input() showDescription = false;
  loading$: Observable<boolean>;
  primaryAddress$: Observable<CustomerAddress>;
  garbageCollectionSchedule$: Observable<GarbageCollectionSchedule>;
  primaryAddressLoaded$: Observable<boolean>;
  addressError$: Observable<string>;
  error$: Observable<{ code: string; message: string }>;
  crmPortalLink = `${env.osvcSSOlink}/${env.osvcCategoryMap.missedCollection}`;
  widgetSettings$: Observable<BaseWidgetSettings>;

  constructor(
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private garbageCollectionStore: GarbageCollectionStore
  ) {}

  ngOnInit(): void {
    this.loading$ = this.garbageCollectionStore.loading$;
    this.error$ = this.garbageCollectionStore.error$;
    this.addressError$ = this.store.pipe(select(selectCustomerAddressErrorMessage));
    this.widgetSettings$ = this.store.pipe(
      select(selectAppSettings),
      map((settings) => settings.widgets.waste)
    );

    this.primaryAddressLoaded$ = this.store.pipe(select(selectCustomerAddressLoaded));
    this.primaryAddress$ = this.store.pipe(select(selectHasPrimaryAddress));

    this.garbageCollectionSchedule$ = this.garbageCollectionStore.garbageSchedule$;
    this.garbageCollectionStore.loadGarbageSchedule(this.primaryAddress$);
  }

  onEdit(address: CustomerAddress) {
    this.store.dispatch(CustomerAddressActions.displayCustomerAddressDialog());
    this.dialog.open(CustomerAddressComponent, {
      data: { mode: 'edit', filter: 'residential', address: address },
      maxWidth: 400,
    });
  }
}

<div class="card">
  <img src="../../../assets/images/svg/myk_logout.svg" alt="Logout icon" class="door-icon" />
  <h1 color="primary">You have been logged out!</h1>
  <div class="content">
    <p>
      Accounts may be logged out manually or automatically after a period of inactivity. You may also be logged out of
      other City of Kingston services.
    </p>
    <p>Log back in to continue using MyKingston.</p>
  </div>

  <button data-testid="log-In" mat-flat-button color="primary" (click)="onLogin()">Log In</button>

  <iframe [src]="source | safe" frameborder="0" style="width: 0; height: 0"></iframe>

  <iframe *ngIf="production" [src]="jotformLogout | safe" frameborder="0" style="width: 0; height: 0"> </iframe>
</div>

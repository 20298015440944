import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerAddress } from '../../customer/customer.model';
import { GoogleMapsService } from '../../utilities/google-maps.service';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent {
  @Input() address: CustomerAddress;
  @Output() addressChange = new EventEmitter<CustomerAddress>();

  mapsApiLoaded$: Observable<boolean>;

  constructor(private googleMapsService: GoogleMapsService) {
    this.mapsApiLoaded$ = this.googleMapsService.getApiLoaded$();
  }

  onChange(address: CustomerAddress) {
    console.log('address search shell emitting', address);
    this.addressChange.emit(address);
  }
}

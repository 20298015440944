<ng-container
  *ngIf="{
    loading: loading$ | async,
    schedule: garbageCollectionSchedule$ | async,
    error: error$ | async,
    address: primaryAddress$ | async,
    addressError: addressError$ | async,
    addressLoaded: primaryAddressLoaded$ | async,
    widgetSettings: widgetSettings$ | async
  } as vm"
>
  <div *ngIf="vm.widgetSettings.visible" class="garbage-info" data-testid="waste-collection-widget">
    <!-- <button (click)="vm.loading = !vm.loading">loaded</button> -->
    <h2>Next waste collection</h2>

    <div class="loading" *ngIf="(vm.loading || (!vm.addressLoaded && loggedIn)) && !vm.addressError; else loaded">
      <div class="item">
        <ngx-skeleton-loader count="2" [theme]="{ width: '60%', 'margin-bottom': '10px' }"></ngx-skeleton-loader>
      </div>
      <ul style="margin: 0">
        <li>
          <ngx-skeleton-loader
            count="1"
            appearance="circle"
            [theme]="{ height: '24px', width: '24px' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ width: '30%' }" class="service-label"></ngx-skeleton-loader>
        </li>
        <li>
          <ngx-skeleton-loader
            count="1"
            appearance="circle"
            [theme]="{ height: '24px', width: '24px' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ width: '40%' }" class="service-label"></ngx-skeleton-loader>
        </li>
        <li>
          <ngx-skeleton-loader
            count="1"
            appearance="circle"
            [theme]="{ height: '24px', width: '24px' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ width: '30%' }" class="service-label"></ngx-skeleton-loader>
        </li>
      </ul>
      <ngx-skeleton-loader [theme]="{ width: '60%', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
    </div>

    <ng-template #loaded>
      <div *ngIf="!vm.error.code && vm.schedule?.events?.length">
        <div *ngFor="let event of vm.schedule?.events" class="garbage-events">
          <div *ngIf="event.services.length > 0">
            <p>
              <span *ngIf="vm.address?.unit">{{ vm.address.unit }} - </span>{{ vm.address.streetAddress }}
            </p>
            <p data-testid="waste-schedule">{{ event.day }} - {{ event.date | date }}</p>
            <app-garbage-services-list [garbageServices]="event.services"></app-garbage-services-list>
          </div>
        </div>

        <div class="action-buttons">
          <div>
            <a
              mat-stroked-button
              [href]="
                'https://www.cityofkingston.ca/resident/garbage-recycling/waste-tools/calendar#rc-place=' +
                vm.schedule.placeId
              "
              target="_blank"
              rel="noopener"
              >Full schedule</a
            >
          </div>
          <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank" data-testid="missed-garbage">
            Report missed pickup
          </a>
        </div>
      </div>
    </ng-template>
    <div *ngIf="!vm.address && !vm.addressError && vm.addressLoaded && !vm.error.code && !showDescription">
      <p>Add a Kingston address to view details of your next waste collection.</p>
      <app-add-residential-address />
    </div>

    <div *ngIf="vm.addressError && !showDescription" class="error-message">
      <app-message-display errorMessage="" [showCloseButton]="false">
        Unable to access your address to show your collection schedule.
      </app-message-display>
    </div>

    <div *ngIf="vm.error.code === 'API_ERROR'">
      <app-message-display [showCloseButton]="false" messageType="error">
        Unable to get your collection schedule.
      </app-message-display>
    </div>
    <div *ngIf="vm.error.code === '204'">
      <app-message-display [showCloseButton]="false" messageType="info" [message]="vm.error.message" />
    </div>

    <div *ngIf="showDescription && (!loggedIn || (vm.error.code === 'NOT_KINGSTON' && !vm.loading) || !vm.address)">
      Stay informed about upcoming waste collection services.
    </div>

    <div *ngIf="vm.error.code === 'NOT_KINGSTON' && !showDescription && !vm.loading">
      <app-message-display [showCloseButton]="false" messageType="info">
        A Kingston address is required for waste collection information.
      </app-message-display>

      <button
        mat-raised-button
        color="accent"
        data-testid="edit-address"
        (click)="onEdit(vm.address)"
        style="margin: 0.5rem 0 0.5rem 0"
      >
        Edit address
      </button>
    </div>
  </div>
</ng-container>

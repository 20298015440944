import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { ServiceCard } from '../../../service-cards/data-access/service-card.model';
import {
  selectServiceCards,
  selectServiceCardsLoading,
} from '../../../service-cards/data-access/store/service-card.selectors';

@Component({
  selector: 'app-feature-list',
  templateUrl: './feature-list.component.html',
  styleUrls: ['./feature-list.component.scss'],
})
export class FeatureListComponent implements OnInit {
  featureItems = [];
  maxItems = env.maxServicesOnHomePage;
  serviceCards$: Observable<ServiceCard[]>;
  loading$: Observable<boolean>;
  popularServiceCards$: Observable<ServiceCard[]>;
  payServiceCards$: Observable<ServiceCard[]>;
  reportServiceCards$: Observable<ServiceCard[]>;
  applyServiceCards$: Observable<ServiceCard[]>;

  constructor(private store: Store) {
    this.serviceCards$ = this.store.pipe(
      select(selectServiceCards),
      map((serviceCards) => serviceCards.filter((serviceCard) => serviceCard.isVisibleFrontPage))
    );
  }

  ngOnInit() {
    this.loading$ = this.store.pipe(select(selectServiceCardsLoading));

    this.popularServiceCards$ = this.serviceCards$.pipe(
      map((serviceCards) => {
        return serviceCards.filter((serviceCard) => serviceCard.tags.includes('popular'));
      })
    );
    this.payServiceCards$ = this.serviceCards$.pipe(
      map((serviceCards) => {
        return serviceCards.filter((serviceCard) => serviceCard.tags.includes('pay'));
      })
    );
    this.reportServiceCards$ = this.serviceCards$.pipe(
      map((serviceCards) => {
        return serviceCards.filter((serviceCard) => serviceCard.tags.includes('report'));
      })
    );
    this.applyServiceCards$ = this.serviceCards$.pipe(
      map((serviceCards) => {
        return serviceCards.filter((serviceCard) => serviceCard.tags.includes('apply'));
      })
    );
    this.featureItems = [
      { title: 'popular', iconName: 'whatshot', services$: this.popularServiceCards$ },
      { title: 'report', iconName: 'flag_circle', services$: this.reportServiceCards$ },
      { title: 'apply', iconName: 'assignment', services$: this.applyServiceCards$ },
      { title: 'pay', iconName: 'credit_card', services$: this.payServiceCards$ },
    ];
  }
}

<ng-container
  *ngIf="{
    loading: loading$ | async,
    requests: serviceRequests$ | async,
    error: error$ | async,
    loggedIn: loggedIn$ | async,
    widgetSettings: widgetSettings$ | async
  } as vm"
>
  <div *ngIf="vm.widgetSettings.visible" class="widget" data-testid="service-request-widget">
    <h2>Newest service request</h2>

    <div *ngIf="vm.error" data-test="error-message" class="error-message">
      <app-message-display errorMessage="Service request error." [showCloseButton]="false">
        Something went wrong and we are unable to show you your service requests. The issue has been reported and we are
        investigating.
      </app-message-display>
    </div>

    <div *ngIf="!vm.error">
      <div data-test="service-request">
        <app-service-request-list [requests]="vm.requests" [loading]="vm.loading" limit="1"></app-service-request-list>
      </div>

      <div class="action-buttons">
        <a
          mat-stroked-button
          [href]="crmQuestionsList"
          target="_blank"
          *ngIf="vm.requests"
          data-test="view-requests"
          data-testid="view-all-service-requests"
        >
          View all requests
        </a>
        <div *ngIf="vm.loggedIn && !vm.loading" data-test="create-request">
          <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank" rel="noopener">Create request</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="{
    isLoggedIn: isLoggedIn$ | async,
    loading: loading$ | async,
    errorMessage: errorMessage$ | async,
    serviceCards: serviceCards$ | async,
    customerPreferences: customerPreferences$ | async
  } as vm"
>
  <h2 class="mat-h1" data-testid="favourite-service">{{ showSearchArea ? 'Services' : 'Favourite services' }}</h2>
  <div class="center" *ngIf="showSearchArea">
    <h2 class="mat-h2">How can we help you?</h2>
  </div>
  <div class="search-area-1" *ngIf="showSearchArea">
    <mat-form-field color="accent" class="search-bar" appearance="outline">
      <mat-label>Search...</mat-label>
      <mat-icon matSuffix>search</mat-icon>
      <input
        aria-label="keyword search for services"
        matInput
        type="search"
        [(ngModel)]="searchText"
        (input)="onSearchInput(searchText)"
        placeholder="Ex. Parking ticket"
        maxlength="100"
        data-testid="service-search-mobile"
      />
    </mat-form-field>

    <mat-form-field color="accent" appearance="outline" class="categories">
      <mat-select
        [(value)]="selectedCategory"
        aria-label="category filter for services"
        data-testid="category-select-old"
        (selectionChange)="onCategoryChange($event.value)"
      >
        <mat-option *ngFor="let option of categories" [value]="option" [attr.data-testid]="'category-' + option">
          {{ option | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search-area-2" *ngIf="showSearchArea">
    <div
      class="wrapper"
      [ngClass]="{
      'mdc-text-field--outlined mdc-text-field--focused inputFocusedLightMode': isSearchInputFocusedLightMode,
    }"
      [ngClass]="{
      'mdc-text-field--outlined mdc-text-field--focused inputFocusedDarkMode': isSearchInputFocusedDarkMode,
    }"
    >
      <div class="custom-input">
        <mat-form-field
          subscriptSizing="dynamic"
          color="accent"
          class="mat-form-category"
          appearance="outline"
          [ngClass]="{
            ' inputFocusedLightMode': isSearchInputFocusedLightMode,
            ' inputFocusedDarkMode': isSearchInputFocusedDarkMode,
            'darkMode':isDarkMode,
          }"
        >
          <mat-select
            [(value)]="selectedCategory"
            aria-label="category filter for services"
            data-testid="category-select-new"
            (selectionChange)="onCategoryChange($event.value)"
          >
            <mat-option [value]="option" *ngFor="let option of categories" [attr.data-testid]="'category-' + option">
              {{ option | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" color="accent" class="mat-form-search" appearance="outline">
          <!-- <mat-label>Search...</mat-label> -->
          <input
            (focusin)="onSearchInputFocus()"
            (blur)="onSearchInputBlur()"
            aria-label="keyword search for services"
            matInput
            type="search"
            [(ngModel)]="searchText"
            (input)="onSearchInput(searchText)"
            maxlength="100"
            data-testid="service-search-new"
            class="search-input"
            placeholder="Search"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="chip-list" *ngIf="!vm.errorMessage && showSearchArea">
    <mat-chip-listbox aria-label="Chip filter selection" selectable #chipList (change)="onChipSelection($event)">
      <mat-chip-option
        *ngFor="let chipValue of chipListValues"
        color="accent"
        [attr.data-testid]="'chip-' + chipValue"
        >{{ chipValue | titlecase }}</mat-chip-option
      >
    </mat-chip-listbox>
  </div>
  <div class="spinner" *ngIf="vm.loading">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </div>
  <div class="card-container" data-testid="service-card-list">
    <ng-container *ngIf="!vm.loading">
      <div
        *ngIf="
          vm.serviceCards.length === 0 &&
          vm.isLoggedIn &&
          selectedTag === 'favourite' &&
          !vm.loading &&
          !vm.errorMessage &&
          !hasFav
        "
      >
        <p>You do not have any favourite services yet.</p>
        <p>Click the heart icon on a service to add it as a favourite.</p>
        <p *ngIf="!showSearchArea">
          <button data-testid="view-service" mat-flat-button routerLink="/services" color="accent">
            View services
          </button>
        </p>
      </div>
      <div *ngIf="!vm.customerPreferences.favServices && selectedTag === 'favourite'">
        You do not have any favourite services yet.
      </div>

      <app-service-card
        [attr.data-testid]="'service-' + serviceCard?.tags"
        *ngFor="let serviceCard of vm.serviceCards"
        [serviceCard]="serviceCard"
        [loggedIn]="isLoggedIn$ | async"
        (toggleFavorite)="onToggleFavourite($event, vm.customerPreferences)"
      ></app-service-card>
    </ng-container>

    <div *ngIf="vm.errorMessage">
      <app-message-display [message]="vm.errorMessage + '.'" [showCloseButton]="false">
        We are aware of the issue and are working on a solution.
      </app-message-display>
    </div>
  </div>
  <div class="filter-result" *ngIf="!vm.loading && !vm.errorMessage && showSearchArea">
    <div
      *ngIf="
        searchText && selectedCategory === 'all categories' && selectedTag === '' && vm.serviceCards.length === 0;
        else mustClearFilters
      "
      data-testid="no-services-found"
    >
      <p class="mat-h2">No services found for {{ searchText }}.</p>
      <p>Try checking your spelling or use more general terms.</p>
      <p class="submit-request">Still can't find what you're looking for?</p>
      <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank" rel="noopener"> Submit a request </a>
    </div>
    <ng-template #mustClearFilters>
      <div *ngIf="!(!vm.isLoggedIn && selectedTag === 'favourite')">
        <p
          *ngIf="
            !(
              vm.serviceCards.length === 0 &&
              vm.isLoggedIn &&
              selectedTag === 'favourite' &&
              !vm.loading &&
              !vm.errorMessage &&
              !hasFav
            )
          "
        >
          Didn't find what you're looking for?
        </p>
        <div *ngIf="selectedTag || selectedCategory !== 'all categories'; else noFilters">
          <button data-testid="clear-filters" mat-flat-button color="primary" (click)="clearFiltersAndSearchAll()">
            Search all services
          </button>
        </div>
        <ng-template #noFilters>
          <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank" rel="noopener">
            Submit a request
          </a>
        </ng-template>
      </div>
    </ng-template>
    <div *ngIf="!vm.isLoggedIn && selectedTag === 'favourite'">
      <h2>Account required</h2>
      <p>You can log in or create an account to see your saved services.</p>
      <app-create-register-account></app-create-register-account>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { CustomerAddress } from '../../customer/customer.model';

@Component({
  selector: 'app-address-manual-entry',
  templateUrl: './address-manual-entry.component.html',
  styleUrls: ['./address-manual-entry.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressManualEntryComponent {
  @Input() address: CustomerAddress;
  @Input() addressTypes = new Set();

  postalMask = 'S0S 0S0';
  postalPlaceholder = 'Ex. A0A 0A0';

  constructor(private cdr: ChangeDetectorRef) {}

  countryChange(event: string) {
    this.setPostalAttributes(event);
  }
  setPostalAttributes(country: string) {
    if (country?.toLowerCase() === 'canada' || country?.toLowerCase() === 'ca') {
      this.postalMask = 'S0S 0S0';
      this.postalPlaceholder = 'Ex. A0A 0A0';
      this.address.postalCode = '';
    } else {
      this.postalMask = null;
      this.postalPlaceholder = null;
    }
    this.cdr.detectChanges();
  }
}

<ng-container
  *ngIf="{
    loggedIn: loggedIn$ | async,
    preferences: preferences$ | async,
    residentialAddress: residentialAddress$ | async,
    addressesLoaded: addressesLoaded$ | async,
    imageSource: imageSource$ | async,
    addressError: addressError$ | async,
    widgetSettings: widgetSettings$ | async
  } as vm"
>
  <div *ngIf="!vm.loggedIn">
    <app-home-banner [imageSource]="vm.imageSource" />
  </div>

  <div *ngIf="vm.loggedIn">
    <h1>Home</h1>
    <div class="widget-container">
      <div *ngIf="vm.widgetSettings.waste.visible" class="widget mat-elevation-z1">
        <app-garbage-collection />
      </div>
      <div *ngIf="true" class="widget mat-elevation-z1">
        <app-fire-permit [loggedIn]="vm.loggedIn" />
      </div>
      <div *ngIf="vm.widgetSettings.service_requests.visible" class="widget mat-elevation-z1">
        <app-service-requests></app-service-requests>
      </div>
      <div *ngIf="vm.widgetSettings.district.visible" class="widget mat-elevation-z1">
        <app-district-shell
          [address]="vm.residentialAddress"
          [addressesLoaded]="vm.addressesLoaded"
          [addressError]="vm.addressError"
        />
      </div>
    </div>

    <div class="widget">
      <app-service-card-list [presetTag]="vm.loggedIn ? 'favourites' : 'popular'" [showSearchArea]="false" />
    </div>
  </div>
  <h2 class="mat-h1" *ngIf="vm.loggedIn">Other services</h2>
  <app-feature-list />
</ng-container>

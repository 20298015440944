import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatestWith, Observable, switchMap } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { AuthenticationService } from '../auth/auth.service';
import { selectAuthPermissions } from '../auth/store/auth.selectors';
import { AppSettings } from './app-settings.model';
import { selectAppSettings } from './store/app-settings.selectors';
@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  constructor(private http: HttpClient, private store: Store, private authService: AuthenticationService) {}

  fetchApplicationSettings(): Observable<AppSettings> {
    return this.http.get<AppSettings>(`${env.serverUrl}/settings`);
  }

  applicationSettings$(): Observable<AppSettings> {
    return this.store.pipe(
      select(selectAppSettings),
      combineLatestWith(this.store.pipe(select(selectAuthPermissions))),
      switchMap(([settings, permissions]) => {
        return this.authService.canAccess$(settings, permissions);
      })
    );
  }
}

<mat-toolbar color="primary" class="toolbar">
  <span *ngIf="mode === 'over'">
    <button mat-icon-button aria-label="menu" (click)="onToggleMenu()" id="menu-button" data-testid="menu">
      <mat-icon>menu</mat-icon>
    </button>
  </span>
  <img
    *ngIf="mode === 'side'"
    class="logo"
    src="../../../assets/images/svg/cityofkingston_logo_header.svg"
    alt="City of Kingston logo"
  />
  <span routerLink="/" style="cursor: pointer" data-testid="app-title" class="toolbar-text">MyKingston</span>

  <span class="header-spacer"></span>

  <span *ngIf="customerName$ | async as user" data-testid="username" class="toolbar-text">
    {{ user.given_name }}
  </span>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu" data-testid="account" id="profile">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['/profile', 0]" data-testid="account-profile" *ngIf="isLoggedIn$ | async">
      <mat-icon>manage_accounts</mat-icon>
      <span>My Account</span>
    </button>

    <button mat-menu-item data-testid="dark-mode" (click)="onToggleDarkMode(darkMode)">
      <!-- <mat-icon>dark_mode</mat-icon> -->
      <mat-slide-toggle
        #darkMode
        class="toggle"
        color="accent"
        (click)="$event.stopPropagation()"
        (change)="onDarkModeSwitched($event)"
        [checked]="(customerPreferences$ | async).darkMode"
      >
        Dark mode
      </mat-slide-toggle>
    </button>

    <button mat-menu-item (click)="onLogout()" *ngIf="isLoggedIn$ | async; else loginButton" data-testid="logout">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
    <ng-template #loginButton>
      <button mat-menu-item (click)="onLogin()" data-testid="login">
        <mat-icon>login</mat-icon>
        <span>Login / Sign-up</span>
      </button>
    </ng-template>
  </mat-menu>
</mat-toolbar>
<mat-progress-bar mode="indeterminate" color="accent" *ngIf="authChecking$ | async"></mat-progress-bar>

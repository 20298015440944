import { Injectable } from '@angular/core';
import { first, Observable, switchMap, tap } from 'rxjs';
import { FirePermit, FireBanZone } from './fire-permit.model';
import { ComponentStore } from '@ngrx/component-store';
import { FirePermitService } from './fire-permit.service';

// State for both FirePermits and FireBanStatus
export interface FirePermitRequestState {
  permits: FirePermit[] | null;
  permitError: string;
  permitLoading: boolean;
  statusData: FireBanZone[] | null;
  statusLoading: boolean;
  statusError: { message: string; code: string } | null;
}

@Injectable({ providedIn: 'root' })
export class FirePermitRequestStore extends ComponentStore<FirePermitRequestState> {
  // State selectors for fire permits
  readonly permits$ = this.select((state) => state.permits);
  readonly permitError$ = this.select((state) => state.permitError);
  readonly permitLoading$ = this.select((state) => state.permitLoading);

  // State selectors for fire ban status
  readonly statusData$ = this.select((state) => state.statusData);
  readonly statusLoading$ = this.select((state) => state.statusLoading);
  readonly statusError$ = this.select((state) => state.statusError);

  constructor(private fireBanService: FirePermitService) {
    super({
      permits: null,
      permitError: '',
      permitLoading: true,
      statusData: null,
      statusError: null,
      statusLoading: true,
    });
  }

  loadFirePermits = this.effect(($) =>
    $.pipe(
      first(),
      switchMap(() => {
        return this.fireBanService.fetchFirePermits().pipe(
          tap({
            next: (permits) =>
              this.patchState({
                permitLoading: false,
                permits: permits
                  ? permits.sort((a, b) => {
                      if (a.created > b.created) return -1;
                      if (a.created < b.created) return 1;
                      return 0;
                    })
                  : null,
              }),
            error: (error: Error) => {
              if (error.message.startsWith('ERROR_CONTACT_NOT_FOUND')) {
                this.patchState({ permitLoading: false, permitError: '', permits: null });
              } else {
                this.patchState({ permitError: error.message, permitLoading: false });
              }
            },
          })
        );
      })
    )
  );

  loadFireBanStatus = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      switchMap(() => {
        this.patchState({ statusLoading: true, statusError: null });

        return this.fireBanService.fetchFirePermitData().pipe(
          tap({
            next: (statusData) => {
              this.patchState({
                statusData,
                statusLoading: false,
                statusError: null,
              });
            },
            error: (error: { errorCode: string; errorMessage: string }) => {
              this.patchState({
                statusData: null,
                statusLoading: false,
                statusError: { message: error.errorMessage, code: 'API_ERROR' },
              });
            },
          })
        );
      })
    )
  );
}

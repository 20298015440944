import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CustomerAddressComponent } from '../../customer/customer-address-list/customer-address/customer-address.component';
import * as CustomerAddressActions from '../../customer/store/actions/customer-address.actions';
import * as fromApp from '../../store/app.reducer';

@Component({
  selector: 'app-add-residential-address',
  templateUrl: './add-residential-address.component.html',
  styleUrls: ['./add-residential-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddResidentialAddressComponent {
  constructor(private dialog: MatDialog, private store: Store<fromApp.AppState>) {}

  onAddAddress() {
    this.store.dispatch(CustomerAddressActions.displayCustomerAddressDialog());
    this.dialog.open(CustomerAddressComponent, {
      data: { mode: 'new', filter: 'residential' },
      maxWidth: 400,
    });
  }
}

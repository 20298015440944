import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { environment as env } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthEffects } from './auth/store/auth.effects';
import { CustomerEffects } from './customer/store/effects/customer.effects';
import { HomeModule } from './home/home.module';
import { LayoutModule } from './layout/layout.module';
import { ServiceCardEffects } from './service-cards/data-access/store/service-card.effects';
import { MfapEffects } from './services/mfap/store/mfap.effects';
import { SharedModule } from './shared/shared.module';
import * as fromApp from './store/app.reducer';
import { InitModule } from './utilities/init.module';
import { ActivityDetectionComponent } from './auth/activity-detection/activity-detection.component';
import { ActivityTimeoutDialogComponent } from './auth/activity-detection/ui/activity-timeout-dialog/activity-timeout-dialog.component';
import { AppSettingsEffects } from './app-settings/store/app-settings.effects';

@NgModule({
  declarations: [AppComponent, ActivityDetectionComponent, ActivityTimeoutDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    LayoutModule,
    HomeModule,
    HttpClientJsonpModule,
    StoreModule.forRoot(fromApp.appReducer),
    EffectsModule.forRoot([AuthEffects, CustomerEffects, MfapEffects, ServiceCardEffects, AppSettingsEffects]),
    StoreDevtoolsModule.instrument({ logOnly: env.production }),
    StoreRouterConnectingModule.forRoot(),
    AuthModule.forRoot({
      //this seems to stop the redirect and allows the state param to stay
      skipRedirectCallback: window.location.pathname === '/error',
      useRefreshTokensFallback: true,

      ...env.auth,
      //attach the auth0 token to every request to the myk_api
      httpInterceptor: {
        allowedList: [
          `${env.serverUrl}/settings*`,
          `${env.serverUrl}/onboarding*`,
          `${env.serverUrl}/customer*`,
          `${env.serverUrl}/customer`,
          // `${env.serverUrl}/services*`,
          `${env.serverUrl}/mfap/*`,
          `${env.serverUrl}/osvc/*`,
          `${env.serverUrl}/waste/*`,
          `${env.serverUrl}/address/*`,
        ],
      },
    }),
    BrowserAnimationsModule,
    InitModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

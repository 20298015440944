import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCheckingAuthState } from 'src/app/auth/store/auth.selectors';
import { environment as env } from '../../../environments/environment';

@Component({
  selector: 'app-content-shell',
  templateUrl: './content-shell.component.html',
  styleUrls: ['./content-shell.component.scss'],
})
export class ContentShellComponent {
  @ViewChild(MatSidenav)
  sidenav: MatSidenav;

  @Input() mode: MatDrawerMode = 'side';
  @Input() set sideNavToggle(value: string) {
    console.log('sidenavtoggle value', value);

    if (value === 'open') this.sidenav?.open();
    if (value === 'close') this.sidenav?.close();
    if (value === 'toggle') this.sidenav?.toggle();
  }

  @Input() hideSideNav = false;
  @Input() loggedIn = false;
  @Input() checkingAuth = false;
  @Output() sideNavClosed = new EventEmitter<void>();

  checkingAuth$: Observable<boolean>;

  constructor(private store: Store) {
    this.checkingAuth$ = this.store.pipe(select(selectCheckingAuthState));
  }

  closeSideNav() {
    if (this.mode == 'over') this.sidenav.toggle();
  }

  navCloseStart() {
    console.log('close started');
    this.sideNavClosed.next(null);
  }

  onFeedback() {
    if (this.mode == 'over') this.sidenav.toggle();
    console.log('user wants to give feedback');

    popupWindow(env.feedbackUrl, 'feedback', window, 800, 600);
  }
}

function popupWindow(url, windowName, win, w, h) {
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
  return win.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  );
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CustomerAddress } from '../../../customer/customer.model';

@Component({
  selector: 'app-address-search-input',
  templateUrl: './address-search-input.component.html',
  styleUrls: ['./address-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchInputComponent implements AfterViewInit {
  @Input() address: CustomerAddress;
  @Output() addressSelected = new EventEmitter<CustomerAddress>();
  hasStreetNumber = true;
  center = { lat: 44.22988110146884, lng: -76.48044577956765 };
  // Create a bounding box with sides ~10km away from the center point
  //https://developers.google.com/maps/documentation/javascript/places-autocomplete#set-options-at-construction
  defaultBounds = {
    north: this.center.lat + 0.1,
    south: this.center.lat - 0.1,
    east: this.center.lng + 0.1,
    west: this.center.lng - 0.1,
  };

  options = {
    types: ['premise', 'street_address', 'subpremise'],
    componentRestrictions: { country: ['ca', 'us'] },
    fields: ['address_components', 'geometry', 'place_id'],
    bounds: this.defaultBounds,
    strictBounds: false,
  };

  @ViewChild('addressSearchField') searchField: ElementRef;
  constructor(private cdr: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    let searchBox = new google.maps.places.Autocomplete(this.searchField.nativeElement);
    searchBox.setOptions(this.options);

    searchBox.addListener('place_changed', () => {
      let place: google.maps.places.PlaceResult = searchBox.getPlace();
      if (place.address_components) this.handleAddressChange(place);
    });
  }
  handleAddressChange(event: any) {
    let address1 = ''; //so we can manipulate civic number and street
    let postCode = ''; //for postal code suffix manipulation
    let hasLocality = false;

    console.log(event);
    this.address = {
      ...this.address,
      lat: event.geometry.location.lat(),
      lng: event.geometry.location.lng(),
      goPlaceId: event.place_id,
      city: '',
      provinceOrTerritory: '',
      country: '',
    };

    for (const component of event.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postCode = `${component.long_name}${postCode}`;
          break;
        }

        case 'postal_code_suffix': {
          postCode = `${postCode}-${component.long_name}`;
          break;
        }

        case 'locality':
          this.address = { ...this.address, city: component.long_name };
          hasLocality = true;
          break;

        case 'administrative_area_level_1': {
          this.address = { ...this.address, provinceOrTerritory: component.long_name };
          break;
        }
        case 'administrative_area_level_3': {
          if (!hasLocality) {
            this.address = { ...this.address, city: component.long_name };
          }
          break;
        }
        case 'administrative_area_level_2': {
          this.address = { ...this.address, region: component.long_name };
          break;
        }
        case 'country':
          this.address = { ...this.address, country: component.long_name };
          break;
      }
    }

    this.address = {
      ...this.address,
      streetAddress: address1,
      postalCode: postCode,
      unit: '',
    };
    console.log('selected address', this.address);
    if (address1 !== '') {
      this.addressSelected.emit(this.address);
    } else {
      this.hasStreetNumber = false;
      this.cdr.detectChanges();
    }
  }
}

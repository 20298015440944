import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment as env } from '../../../environments/environment';
import { SharedModule } from '../../shared/shared.module';
import * as AuthActions from '../store/auth.actions';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent {
  constructor(private store: Store) {}
  source = env.osvcHost + '/ci/pta/logout';
  jotformLogout = 'https://cityofkingston.jotform.com/logout.php';
  production = env.production;
  onLogin() {
    this.store.dispatch(AuthActions.login());
  }
}

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-ui-email-verify',
  templateUrl: './ui-email-verify.component.html',
  styleUrls: ['./ui-email-verify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiEmailVerifyComponent {
  @Input() backNav = true;
  @Input() instructions_label = 'Verify your email address by entering the 6 digit code emailed to you.';
  @Input() loading = false;
  @Input() error = '';
  @Output() verifyCode: EventEmitter<string> = new EventEmitter();
  @Output() resendCode: EventEmitter<boolean> = new EventEmitter();

  verificationCode: string;

  onVerify() {
    this.verifyCode.emit(this.verificationCode);
  }
  onResend() {
    this.resendCode.emit();
  }
}

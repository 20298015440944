import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FirePermit } from '../../data-access/fire-permit.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fire-permit-list',
  templateUrl: './fire-permit-list.component.html',
  styleUrls: ['./fire-permit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class FirePermitListComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { permits: Observable<FirePermit[]> }
  ) {}
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { interval, map, Observable, take } from 'rxjs';
import { ActivityTimeoutDialogComponent } from '../ui/activity-timeout-dialog/activity-timeout-dialog.component';
import * as fromApp from '../../../store/app.reducer';
import * as AuthActions from '../../store/auth.actions';
import { environment as env } from '../../../../environments/environment';
import { Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root',
})
export class ActivityDetectionService {
  logoutCountdownSeconds: number = env.activityCountdownTimerSeconds;

  constructor(private dialog: MatDialog, private store: Store<fromApp.AppState>, private titleService: Title) {}

  countdown$: Observable<number> = interval(1000).pipe(
    take(this.logoutCountdownSeconds),
    map((x) => {
      return this.logoutCountdownSeconds - x;
    })
  );

  showInactivityWarning() {
    const currentTitle = this.titleService.getTitle();
    if (!this.dialog.openDialogs.length)
      this.dialog
        .open(ActivityTimeoutDialogComponent)
        .afterClosed()
        .subscribe((logout) => {
          if (logout) {
            this.store.dispatch(AuthActions.logout());
          }
          this.titleService.setTitle(currentTitle);
          this.store.dispatch(AuthActions.extendLogoutTimer());
        });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment as env } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  apiLoaded$: Observable<boolean>;
  apiLoaded = false;

  constructor(private http: HttpClient) {}

  getApiLoaded$(): Observable<boolean> {
    if (this.apiLoaded) return of(this.apiLoaded);
    else return this.init();
  }

  init(): Observable<boolean> {
    return (this.apiLoaded$ = this.http.jsonp(env.google_maps_api_key, 'callback').pipe(
      map(() => {
        this.apiLoaded = true;
        return true;
      }),
      catchError(() => of(false))
    ));
  }
}

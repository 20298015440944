import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import {
  Customer,
  CustomerAddress,
  CustomerEmail,
  CustomerFamilyMember,
  CustomerPhone,
  CustomerPreferences,
} from './customer.model';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  userId: string = null;

  constructor(private http: HttpClient) {}

  // get customerId(): string {
  //   return this.userId;
  // }

  calculate_age(dob: Date) {
    let birthDate = new Date(dob);
    let diff = Date.now() - birthDate.getTime();
    let ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  overAgeDependent(member: CustomerFamilyMember): boolean {
    return member.roleType == 'dependent' && this.calculate_age(member.dateOfBirth) >= 18;
  }

  fetchCustomer(): Observable<Customer> {
    return this.http.get<Customer>(`${env.serverUrl}/customer`);
  }
  /**
   * Get the customer preferences from the myk API. Save the customer preferences to local storage.
   * @returns preferences of the customer as a CustomerPreferences observable
   */
  fetchCustomerPreferences(): Observable<CustomerPreferences> {
    return this.http
      .get<CustomerPreferences>(`${env.serverUrl}/customer/preferences`)
      .pipe(
        tap((preferences) => localStorage.setItem('preferences', JSON.stringify({ darkMode: preferences.darkMode })))
      );
  }

  /**
   * Save the customer prefences to the dynamoDB myk table.
   * Save the customer preferences in local storage as well.
   * @param preferences customer preferences to save
   * @returns Most recent version of the customer prefences from the myk api.
   */
  saveCustomerPreferences(preferences: CustomerPreferences): Observable<CustomerPreferences> {
    //save preferences in local storage

    localStorage.setItem('preferences', JSON.stringify({ darkMode: preferences.darkMode }));
    return this.http.patch<CustomerPreferences>(`${env.serverUrl}/customer/preferences`, { preferences });
  }

  fetchCustomerEmails(): Observable<CustomerEmail[]> {
    return this.http.get<CustomerEmail[]>(`${env.serverUrl}/customer/emails`);
  }

  fetchCustomerAddresses(): Observable<CustomerAddress[]> {
    return this.http.get<CustomerAddress[]>(`${env.serverUrl}/customer/addresses`);
  }

  fetchCustomerPhones(): Observable<CustomerPhone[]> {
    return this.http.get<CustomerPhone[]>(`${env.serverUrl}/customer/phones`);
  }

  fetchCustomerFamily(): Observable<CustomerFamilyMember[]> {
    return this.http.get<CustomerFamilyMember[]>(`${env.serverUrl}/customer/family`);
  }
  updateCustomer(customer: Customer): Observable<Customer> {
    return this.http.patch<Customer>(`${env.serverUrl}/customer`, customer);
  }
  updateCustomerAddress(customerAddress: CustomerAddress): Observable<CustomerAddress> {
    console.log('starting update customer address');
    return this.http.patch<CustomerAddress>(
      `${env.serverUrl}/customer/addresses/${customerAddress.addressId}`,
      customerAddress
    );
  }
  updateCustomerPhone(customerPhone: CustomerPhone): Observable<CustomerPhone> {
    return this.http.patch<CustomerPhone>(`${env.serverUrl}/customer/phones/${customerPhone.phoneId}`, customerPhone);
  }
  createCustomerAddress(customerAddress: CustomerAddress): Observable<CustomerAddress> {
    console.log('starting create customer address');
    return this.http.post<CustomerAddress>(`${env.serverUrl}/customer/addresses`, customerAddress);
  }
  storeCustomerFamilyMember(customerFamilyMembers: CustomerFamilyMember[]): Observable<CustomerFamilyMember[]> {
    return this.http.post<CustomerFamilyMember[]>(`${env.serverUrl}/customer/family`, customerFamilyMembers);
  }
  createCustomerPhone(customerPhone: CustomerPhone): Observable<CustomerPhone> {
    return this.http.post<CustomerPhone>(`${env.serverUrl}/customer/phones`, customerPhone);
  }

  createCustomerEmail(customerEmail: CustomerEmail): Observable<CustomerEmail> {
    return this.http.post<CustomerEmail>(`${env.serverUrl}/customer/emails`, customerEmail);
  }
  deleteCustomerEmail(emailId: string): Observable<any> {
    return this.http.delete(`${env.serverUrl}/customer/emails/${emailId}`);
  }

  updateCustomerEmail(email: CustomerEmail): Observable<any> {
    return this.http.patch(`${env.serverUrl}/customer/emails/${email.emailId}`, email);
  }
  resendCustomerEmailVerification(email: CustomerEmail): Observable<any> {
    const copyEmail: CustomerEmail = { ...email, resendCode: true };
    console.log(copyEmail);
    return this.http.patch(`${env.serverUrl}/customer/emails/${email.emailId}`, copyEmail);
  }

  deleteCustomerAddress(addressId: string): Observable<any> {
    return this.http.delete(`${env.serverUrl}/customer/addresses/${addressId}`);
  }
  deleteCustomerPhone(phoneId: string): Observable<any> {
    return this.http.delete(`${env.serverUrl}/customer/phones/${phoneId}`);
  }
}

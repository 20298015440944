import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../store/auth.actions';
@Injectable({
  providedIn: 'root',
})
export class LogoutGuard {
  constructor(private authService: AuthService, private store: Store<fromApp.AppState>) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated$.pipe(
      map((loggedIn) => {
        if (loggedIn) {
          console.log('Cannot access this page because logged in is true');
          this.store.dispatch(AuthActions.logout());
        }
        return !loggedIn;
      })
    );
  }
}

<ng-container
  *ngIf="{
    loading: loading$ | async,
    requests: firePermitRequests$ | async,
    error: error$ | async,
    widgetSettings: widgetSettings$ | async
  } as vm"
>
  <div *ngIf="vm.widgetSettings.visible" class="widget" data-testid="fire-permit-widget">
    <h2>Open air fire permit</h2>

    <app-fire-ban-status />

    <div class="recentFirePermit">Most recent open air fire permit</div>

    <div *ngIf="vm.error" data-test="error-message" class="error-message">
      <app-message-display errorMessage="Service request error." [showCloseButton]="false">
        Something went wrong and we are unable to show you your permits. The issue has been reported and we are
        investigating.
      </app-message-display>
    </div>

    <div *ngIf="!vm.error">
      <div *ngIf="vm.loading; else loaded">
        <div style="width: 50%">
          <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
        </div>

        <div style="width: 40%">
          <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
        </div>

        <div style="width: 50%">
          <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
        </div>

        <div style="width: 60%">
          <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
        </div>
      </div>

      <ng-template #loaded>
        <div *ngIf="vm.requests?.length; else noPermit">
          <div class="table">
            <div class="tr">
              <div class="td label">Type:</div>
              <div class="td last">{{ vm.requests[0]?.subject }}</div>
            </div>

            <div *ngIf="vm.requests[0]?.fire_zone_id" class="tr">
              <div class="td label first">Zone:</div>
              <div class="td">{{ vm.requests[0]?.fire_zone_id }}</div>
            </div>

            <div class="tr">
              <div class="td label">Expiry date:</div>
              <div class="td">{{ vm.requests[0]?.date_expires | date }}</div>
            </div>
            <div class="tr">
              <div class="td label">Address:</div>
              <div class="td">{{ vm.requests[0]?.geo_street_address }}</div>
            </div>
          </div>
        </div>

        <ng-template #noPermit>
          <p>No fire permits found.</p>
        </ng-template>
      </ng-template>

      <div *ngIf="loggedIn && !vm.loading" class="action-buttons">
        <button
          mat-stroked-button
          *ngIf="vm.requests?.length; else permitDetails"
          (click)="onAllPermits()"
          data-testid="view-all-fire-permit"
        >
          View all permits
        </button>

        <ng-template #permitDetails>
          <a
            mat-stroked-button
            href="https://www.cityofkingston.ca/emergency-services-and-public-health/open-air-fire-permits/"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="permit-details-link"
          >
            Permit details
          </a>
        </ng-template>

        <div data-test="create-request">
          <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank">Apply for permit</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-back-bar',
  templateUrl: './back-bar.component.html',
  styleUrls: ['./back-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackBarComponent {
  @Input() backRoute: string = '';
  @Input() backLabel: string = 'back';
  constructor(private router: Router, private route: ActivatedRoute) {}

  onBack() {
    this.router.navigate([this.backRoute], { relativeTo: this.route });
  }
}

<ng-container
  *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    statusData: statusData$ | async
  } as vm"
>
  <div class="fireBanStatus">Fire ban status</div>

  <div *ngIf="!vm.error">
    <div *ngIf="vm.loading; else loaded">
      <div style="width: 50%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
      </div>

      <div style="width: 40%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
      </div>
    </div>

    <ng-template #loaded class="zone-statuses-wrapper">
      <div class="zone-status">
        <div>Zone 1:</div>
        <div [ngClass]="getStatusChipClass(vm.statusData?.zone1Status)" class="custom-chip">
          {{ vm.statusData?.zone1Status }}
        </div>
      </div>

      <div class="zone-status">
        <div>Zone 2:</div>
        <div [ngClass]="getStatusChipClass(vm.statusData?.zone2Status)" class="custom-chip">
          {{ vm.statusData?.zone1Status }}
        </div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="vm.error" data-test="error-message" class="error-message">
    <app-message-display [showCloseButton]="false"> Unable to retrieve current zone status. </app-message-display>
  </div>
</ng-container>

<div *ngIf="addressTypes.size > 1; else forcedType">
  <label id="address-type-radio-group-label">Select your address type</label>
  <br />

  <mat-radio-group
    required
    aria-labelledby="address-type-radio-group-label"
    name="addressType"
    class="address-type-radio-group"
    [(ngModel)]="address.addressType"
    data-testid="address-type"
  >
    <mat-radio-button *ngFor="let type of addressTypes" [value]="type" [attr.data-testid]="type">
      {{ type | titlecase }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<ng-template #forcedType>
  <div>
    <p>
      This address will be saved as a
      {{ addressTypes.has('mailing') ? 'Mailing' : 'Residential' }} address
    </p>
  </div>
</ng-template>
<div class="unit-number-radio-group">
  <label id="unit-number-radio-group-label">Do you have a unit number?</label>
  <br />
  <mat-radio-group
    [(ngModel)]="unitNumberSelection"
    name="unitNumberSelection"
    required
    class="address-type-radio-group"
    aria-labelledby="unit-number-radio-group-label"
  >
    <mat-radio-button (change)="onUnitNumberSelectionChange('yes')" value="yes" [attr.data-testid]="'yes'"
      >Yes</mat-radio-button
    >
    <mat-radio-button (change)="onUnitNumberSelectionChange('no')" value="no" [attr.data-testid]="'no'"
      >No</mat-radio-button
    >
  </mat-radio-group>
</div>

<mat-form-field color="accent" appearance="outline" *ngIf="unitNumberSelection === 'yes'">
  <mat-label>Unit number</mat-label>
  <input
    type="text"
    matInput
    name="unit"
    autocomplete="off"
    data-testid="unit"
    maxlength="10"
    data-testid="unit-number"
    [(ngModel)]="address.unit"
    (ngModelChange)="onUnitNumberChange()"
    required
  />
  <mat-error *ngIf="unitNumberSelection === 'yes' && !address.unit"> Unit number is required </mat-error>
</mat-form-field>

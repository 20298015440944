import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.description,
  release: environment.version,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new BrowserTracing({
      tracingOrigins: [environment.serverUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && environment.test) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }

    const error = hint.originalException as Error;
    console.log(error);
    if (error && error && error.message && error.message.includes('ERROR_CONTACT_NOT_FOUND')) return null;
    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.sentry.tracesSampleRate, //portion of transactions to be sampled
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  //.then((module) => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
  .catch((err) => console.error(err));

<mat-card appearance="outlined" class="service-card">
  <mat-card-header>
    <mat-card-subtitle *ngIf="serviceCard.signInRequired && loggedIn === false"> Account required </mat-card-subtitle>
    <mat-card-title>
      <span role="heading" aria-level="3"> {{ serviceCard.name }}</span></mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="card-content">
    <p style="margin-top: 1rem">
      {{ serviceCard.description }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <div>
      <a [href]="linkHref" target="_blank">
        <button mat-stroked-button aria-label="Service start" (click)="onServiceClick()" data-testid="start">
          <span
            *ngIf="(customer$ | async)?.mfapId && serviceCard.name === 'Municipal Fee Assistance Program'; else noMfap"
            >View</span
          >
          <ng-template #noMfap>Start</ng-template>
        </button>
      </a>
      <a [href]="this.serviceCard.descriptionUrl" target="_blank">
        <button
          mat-button
          style="margin-left: 1rem"
          aria-label="Service details"
          (click)="onDetailsClick()"
          *ngIf="serviceCard.descriptionUrl"
          [attr.data-testid]="'details-' + serviceCard.serviceId"
        >
          Details
        </button>
      </a>
    </div>
    <div *ngIf="loggedIn">
      <button
        mat-icon-button
        color="accent"
        aria-hidden="false"
        aria-label="toggle favourite"
        (click)="onFavorite(serviceCard.serviceId)"
        [attr.data-testid]="'fav-' + serviceCard.serviceId"
      >
        <mat-icon>{{ serviceCard.isFavorite ? 'favorite' : 'favorite_border' }}</mat-icon>
      </button>
    </div>
  </mat-card-actions>
</mat-card>

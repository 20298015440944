<ng-container *ngIf="{ isSaving: isSaving$ | async } as vm">
  <div class="my-container">
    <h1 class="mat-h2" mat-dialog-title>{{ data.mode === 'edit' ? 'Edit address' : 'Add address' }}</h1>

    <mat-dialog-content style="padding-top: 0.5rem">
      <div *ngIf="!manualEntry && !addressSelected">
        <app-address-search [address]="address" (addressChange)="onAddressSelected($event)"></app-address-search>
      </div>
      <!-- <button mat-button (click)="onLogForm()" type="button">log form</button> -->
      <form
        #addressForm="ngForm"
        (ngSubmit)="onSubmitAddress()"
        id="address-manual-form"
        [style.display]="manualEntry ? 'inline' : 'none'"
      >
        <app-address-manual-entry [(address)]="address" [addressTypes]="addressTypes"></app-address-manual-entry>
      </form>
      <div *ngIf="addressSelected">
        <p>
          {{ address?.streetAddress }}
          <br />
          {{ address?.city }}, {{ address?.provinceOrTerritory }}, {{ address?.country }} <br />
          {{ address?.postalCode }}
        </p>
      </div>
      <div [style.display]="addressSelected ? 'inline' : 'none'">
        <form (ngSubmit)="onSubmitAddress()" #addressDetailForm="ngForm" id="address-details-form">
          <app-customer-address-detail
            (unitNumberSelectionEmitter)="handleUnitNumberSelection($event)"
            (unitNumber)="unitNumberInput()"
            [address]="address"
            [addressTypes]="addressTypes"
          ></app-customer-address-detail>
        </form>
      </div>

      <button
        *ngIf="!addressSelected"
        mat-stroked-button
        (click)="toggleForm()"
        [data-testid]="manualEntry ? 'back-to-search' : 'address-not-found'"
      >
        {{ manualEntry ? 'Back to search' : 'Enter address manually' }}
      </button>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="action-buttons">
      <mat-progress-bar mode="indeterminate" aria-label="loading" *ngIf="vm.isSaving"></mat-progress-bar>

      <div class="dialog-buttons" [ngClass]="!addressSelected ? 'action-buttons' : ''">
        <button mat-flat-button type="button" (click)="onCancel()" data-testid="cancel" [disabled]="vm.isSaving">
          Cancel
        </button>

        <button
          *ngIf="!manualEntry"
          mat-flat-button
          [disabled]="
            vm.isSaving ||
            (manualEntry && !addressForm.valid) ||
            !addressSelected ||
            !unitNumberSelection ||
            (!unitNumber && unitNumberSelection === 'yes')
          "
          color="accent"
          data-testid="save"
          type="submit"
          form="address-details-form"
        >
          Save
        </button>
        <button
          *ngIf="manualEntry"
          mat-flat-button
          [disabled]="vm.isSaving || (manualEntry && !addressForm.valid)"
          color="accent"
          data-testid="save"
          form="address-manual-form"
          type="submit"
        >
          Save
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-container>

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { startWith, tap } from 'rxjs';
import { ActivityDetectionService } from '../../data-access/activity-detection.service';
import * as fromApp from '../../../../store/app.reducer';
import * as AuthActions from '../../../store/auth.actions';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-activity-timeout-dialog',
  templateUrl: './activity-timeout-dialog.component.html',
  styleUrls: ['./activity-timeout-dialog.component.scss'],
})
export class ActivityTimeoutDialogComponent {
  constructor(
    private activityService: ActivityDetectionService,
    private store: Store<fromApp.AppState>,
    private titleService: Title
  ) {}

  timeLeft$ = this.activityService.countdown$.pipe(
    startWith(this.activityService.logoutCountdownSeconds),
    tap((secondsLeft: number) => {
      this.titleService.setTitle(`(${secondsLeft}) Inactive - MyKingston`);
      console.log(secondsLeft);
      if (secondsLeft == 1) {
        console.log('logging out');
        this.store.dispatch(AuthActions.logout());
      }
    })
  );
}

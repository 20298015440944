import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CustomerAddress } from '../../../../customer/customer.model';

@Component({
  selector: 'app-customer-address-detail',
  templateUrl: './customer-address-detail.component.html',
  styleUrls: ['./customer-address-detail.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CustomerAddressDetailComponent {
  @Input() address: CustomerAddress;
  @Input() addressTypes: Set<string>;
  @Output() unitNumberSelectionEmitter = new EventEmitter<string>();
  @Output() unitNumber = new EventEmitter<boolean>();
  unitNumberSelection: string;

  onUnitNumberSelectionChange(selectedValue: string) {
    this.unitNumberSelectionEmitter.emit(selectedValue);
  }
  onUnitNumberChange() {
    this.unitNumber.emit(true);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { FireBanZone, FirePermit } from './fire-permit.model';

@Injectable({
  providedIn: 'root',
})
export class FirePermitService {
  constructor(private http: HttpClient) {}

  // fetch fire permit data
  fetchFirePermitData(): Observable<FireBanZone[]> {
    return this.http.get<FireBanZone[]>(`${env.serverUrl}/address/fire-zones`).pipe(
      catchError((err) => {
        let errorMessage = 'Error fetching fire permit data';
        if (err.error.errorCode === 'ERROR_PERMIT_NOT_FOUND') {
          errorMessage = 'No permit data was found.';
        }
        if (err.error.errorCode === 'ERROR_SERVER') {
          errorMessage = 'There was an issue contacting the server.';
        }

        return throwError(() => {
          return { errorCode: err.error.errorCode, errorMessage };
        });
      })
    );
  }

  fetchFirePermits(): Observable<FirePermit[] | null> {
    return this.http.get<FirePermit[]>(`${env.serverUrl}/osvc/incidents`).pipe(
      map((response) => {
        const permits = Array.isArray(response) ? response : [];
        const filteredPermits = permits?.filter((permit) => permit.product_id === '2713');
        return filteredPermits.length > 0 ? filteredPermits : null;
      }),
      catchError((err) => {
        return throwError(() => new Error(err.error?.errorCode || `unknown error, ${err}`));
      })
    );
  }
}

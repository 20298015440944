import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceCardModule } from '../service-cards/service-card.module';
import { SharedModule } from '../shared/shared.module';
import { DistrictShellComponent } from '../widgets/district-details/district-shell.component';
import { GarbageCollectionComponent } from '../widgets/garbage-collection/garbage-collection.component';
import { ServiceRequestsComponent } from '../widgets/service-requests/service-requests.component';
import { FirePermitComponent } from '../widgets/fire-permit/fire-permit.component';
import { HomeComponent } from './home.component';
import { FeatureItemComponent } from './ui/feature-list/feature-item/feature-item.component';
import { FeatureListComponent } from './ui/feature-list/feature-list.component';
import { HomeBannerComponent } from './ui/home-banner/home-banner.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
];

@NgModule({
  declarations: [HomeComponent, FeatureListComponent, HomeBannerComponent, FeatureItemComponent],
  imports: [
    SharedModule,
    ServiceCardModule,
    GarbageCollectionComponent,
    ServiceRequestsComponent,
    DistrictShellComponent,
    FirePermitComponent,
    RouterModule.forChild(routes),
  ],
})
export class HomeModule {}

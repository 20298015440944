import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChipListbox, MatChipListboxChange } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, startWith, withLatestFrom } from 'rxjs';
import { AuthenticationService } from '../auth/auth.service';
import * as AuthActions from './../auth/store/auth.actions';
import { CustomerPreferences } from './../customer/customer.model';
import * as CustomerActions from './../customer/store/actions/customer.actions';
import { selectCustomerPreferences } from './../customer/store/selectors/customer.selector';
import * as fromApp from './../store/app.reducer';
import { ServiceCard } from './data-access/service-card.model';
import * as ServiceCardActions from './data-access/store/service-card.actions';
import { environment as env } from '../../environments/environment';
import {
  selectServiceCards,
  selectServiceCardsErrorMessage,
  selectServiceCardsLoaded,
  selectServiceCardsLoading,
} from './data-access/store/service-card.selectors';
@Component({
  selector: 'app-service-card-list',
  templateUrl: './service-card-list.component.html',
  styleUrls: ['./service-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCardListComponent implements OnInit, AfterViewInit {
  crmPortalLink = '';
  @Input() presetTag = '';
  @Input() showSearchArea = true;
  selectedCategory = 'all categories';
  selectedTag = '';
  hasFav = false;

  @ViewChild('chipList') chipList: MatChipListbox;
  serviceCards$: Observable<ServiceCard[]>;
  customerPreferences: CustomerPreferences;
  loading$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;
  errorMessage$: Observable<string>;
  isSearchInputFocusedDarkMode = false;
  isSearchInputFocusedLightMode = false;
  isDarkMode = false;
  isLoading = true;
  searchText = '';
  searchText$ = new BehaviorSubject<string>('');
  category$ = new BehaviorSubject<string>('');
  chip$ = new BehaviorSubject<string>(this.presetTag);
  destroyRef = inject(DestroyRef);
  chipListValues: string[] = ['favourite', 'popular', 'pay', 'report', 'apply'];
  categories: string[] = [
    'all categories',
    'accessibility',
    'business',
    'garbage',
    'home',
    'life events',
    'parking',
    'parks',
    'permit',
    'pets',
    'recreation',
    'subsidy',
    'tax',
    'transit',
  ];
  customerPreferences$: Observable<CustomerPreferences>;

  //TODO make the chips work.
  constructor(
    private authService: AuthenticationService,
    private store: Store<fromApp.AppState>,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.crmPortalLink = env.osvcSSOlink;
    this.chip$.next(this.presetTag);
    this.authService.user$
      .pipe(takeUntilDestroyed(this.destroyRef), withLatestFrom(this.store.select(selectServiceCardsLoaded)))
      .subscribe(([user, loaded]) => {
        if (user && !loaded) this.store.dispatch(ServiceCardActions.fetchServiceCardsByCustomer());
        if (!user && !loaded) {
          this.store.dispatch(ServiceCardActions.fetchAllServiceCards());
        }
      });

    this.loading$ = this.store.pipe(select(selectServiceCardsLoading), startWith(true));
    this.customerPreferences$ = this.store.pipe(select(selectCustomerPreferences));
    this.errorMessage$ = this.store.pipe(select(selectServiceCardsErrorMessage));
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.customerPreferences$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((preferences) => {
      if (preferences) {
        this.customerPreferences = preferences;
        this.isDarkMode = preferences.darkMode;
      }
    });
    this.serviceCards$ = combineLatest([
      this.store.pipe(select(selectServiceCards)),
      this.searchText$,
      this.category$,
      this.chip$,
    ]).pipe(
      map(([serviceCards, searchText, category, chip]) => {
        let filteredCards = [...serviceCards];
        console.log('trying to filter on', chip);
        const apply_chip_filter = {
          all: () => filteredCards,
          favourite: () => filteredCards.filter((card) => card.isFavorite),
          new: () => filteredCards.filter(isPopular),
        };
        this.hasFav = serviceCards.some((card) => card.isFavorite);
        if (chip) {
          this.selectedTag = chip;
          filteredCards =
            chip in apply_chip_filter
              ? apply_chip_filter[chip]()
              : filteredCards.filter((card) => card.tags.includes(chip));
        }

        if (searchText) {
          filteredCards = filteredCards.filter((card) => card.name.toLowerCase().includes(searchText));
        }
        //apply the category filter
        if (category) {
          filteredCards = filteredCards.filter((card: ServiceCard) => card.tags.includes(category));
        }
        return filteredCards;
      })
    );
  }
  onSearchInputFocus() {
    if (this.customerPreferences.darkMode) {
      this.isSearchInputFocusedDarkMode = true;
    } else {
      this.isSearchInputFocusedLightMode = true;
    }
  }
  onSearchInputBlur() {
    this.isSearchInputFocusedDarkMode = false;
    this.isSearchInputFocusedLightMode = false;
  }

  ngAfterViewInit(): void {
    document.querySelector('main').scroll(0, 0);
    if (!this.showSearchArea) {
      this.selectedTag = 'favourite';
      this.chip$.next('favourite');
    }
    this.route.queryParams.subscribe((params) => {
      console.log('Named Parameter Values:', params);

      if (params['category']) {
        if (this.categories.some((category) => category.toLowerCase() === params['category'])) {
          this.onCategoryChange(params['category']);
          this.selectedCategory = params['category'];
        } else {
          this.chip$.next(params['category']);
          this.selectChipByValue(params['category']);
        }
      }
      if (params['search']) {
        this.onSearchInput(params['search']);
        this.searchText = params['search'];
      }
    });
    this.cdr.detectChanges();
  }

  selectChipByValue(chipValue: string) {
    const chipToSelect = this.chipList?._chips.find((chip) => chip.value.toLowerCase() === chipValue.toLowerCase());
    console.log('found chip to select', chipToSelect, 'from:', this.chipList?._chips);
    if (chipToSelect) {
      chipToSelect.select();
    }
  }

  onSearchInput(searchText: string) {
    this.searchText$.next(searchText?.toLowerCase());
  }
  onCategoryChange(category: string) {
    if (category === 'all categories') category = '';
    this.category$.next(category.toLowerCase());
  }

  onChipSelection(chip: MatChipListboxChange) {
    //chip.toggleSelected();
    console.log('selected chip is', chip?.value);

    this.presetTag = chip?.value?.toLowerCase();

    if (!this.chipList._chips.some((chip) => chip.selected)) {
      this.chip$.next('');
    }

    if (chip.source.selected && !chip.source.disabled) {
      console.log('trying to next:', this.presetTag);
      this.selectedTag = chip.value.toLowerCase();
      this.chip$.next(this.presetTag);
    } else {
      this.selectedTag = '';
    }
  }

  onLogin() {
    this.store.dispatch(AuthActions.login());
  }
  clearFiltersAndSearchAll() {
    this.selectedCategory = 'all categories';
    this.selectedTag = '';
    // this.searchText = '';

    this.chipList?._chips.forEach((chip) => chip.deselect());
    this.chip$.next('');
    // this.onSearchInput(this.searchText);
    this.onCategoryChange(this.selectedCategory); //reuse existing method
  }

  onToggleFavourite(serviceId: string, customerPreferences: CustomerPreferences) {
    console.log('SERVICE SET AS FAVOURITE', serviceId);
    let newPreferences: CustomerPreferences = { ...customerPreferences };
    //add to array if it does not exists, delete if it exists
    console.log(newPreferences);
    let favIndex = -2;
    if (Array.isArray(newPreferences.favServices))
      favIndex = newPreferences.favServices.findIndex((service) => service === serviceId);
    else newPreferences.favServices = [];
    const newFavourites = [...newPreferences.favServices];
    console.log('index is', favIndex, newPreferences);
    if (favIndex > -1) {
      newFavourites.splice(favIndex, 1);
      newPreferences.favServices = newFavourites;
    } else {
      console.log('PUSHING TO FAVS ARRAY', serviceId);
      newFavourites.push(serviceId);
      newPreferences.favServices = newFavourites;
    }
    console.log('DISPATCHING SAVE PREFS WITH PAYLOAD', newPreferences);
    this.store.dispatch(CustomerActions.saveCustomerPreferences({ payload: newPreferences }));
  }
}
//function to determine if a card has been updated in the last 30 days.
const isPopular = (card: ServiceCard) => {
  const now = new Date();
  const dateUpdated = new Date(card.dateUpdated);
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;

  const timeDiffInMs = now.getTime() - dateUpdated.getTime();

  return timeDiffInMs <= thirtyDaysInMs;
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ServiceCard } from '../../../../service-cards/data-access/service-card.model';

@Component({
  selector: 'app-feature-item',
  templateUrl: './feature-item.component.html',
  styleUrls: ['./feature-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureItemComponent {
  @Input() featureName = { title: '', iconName: '' };
  @Input() services: ServiceCard[];
  @Input() loading = false;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-label-data-flex',
  templateUrl: './label-data-flex.component.html',
  styleUrls: ['./label-data-flex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelDataFlexComponent {
  @Input() label = '';
  @Input() index: number;
  @Input() customEditButton: string = null;
  @Input() buttons = { edit: { visible: true, label: 'Edit' }, remove: { visible: true, label: 'Remove' } };
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();

  onEdit() {
    this.edit.emit(null);
  }
  onRemove() {
    this.remove.emit(null);
  }

  /**
   * Helper function to get the word to append to data-testid="edit-<return value>"
   * @param label the label of the family/phone/address element
   * @returns a 1 word lowercase version of the label
   */
  getDataCyName(label: string): string {
    const dataCy = label.split(' ')[0].toLowerCase();
    return dataCy;
  }
}

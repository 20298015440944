import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentCustomer } from '../../../../app/customer/store/selectors/customer.selector';
import { Customer } from '../../../customer/customer.model';
import * as fromApp from '../../../store/app.reducer';
import { ServiceCard } from '../../data-access/service-card.model';

interface SzFunction extends Function {
  push: (...args: any[]) => void;
}
declare const _sz: SzFunction;
@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCardComponent implements OnInit {
  @Input() serviceCard: ServiceCard;
  @Input() loggedIn = false;
  @Output() toggleFavorite: EventEmitter<string> = new EventEmitter();

  customer$: Observable<Customer>;
  linkHref: string;

  constructor(private store: Store<fromApp.AppState>) {}
  ngOnInit() {
    this.customer$ = this.store.pipe(select(selectCurrentCustomer));
    this.linkHref =
      this.serviceCard.initiateUrlSSO && this.loggedIn ? this.serviceCard.initiateUrlSSO : this.serviceCard.initiateUrl;

    if (this.serviceCard.signInRequired) {
      this.linkHref = this.serviceCard.initiateUrlSSO;
    }
  }

  onServiceClick() {
    if (_sz) {
      _sz.push(['event', 'Service Card', 'Start', this.serviceCard.friendlyId]);
    }
  }
  onDetailsClick() {
    if (_sz) {
      _sz.push(['event', 'Service Card', 'Details', this.serviceCard.friendlyId]);
    }
  }

  onFavorite(serviceId: string) {
    this.toggleFavorite.emit(serviceId);
  }
}

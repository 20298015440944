import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-message-display',
  templateUrl: './message-display.component.html',
  styleUrls: ['./message-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDisplayComponent {
  @Input() message = '';
  @Input() showCloseButton = true;
  @Input() messageType: 'info' | 'error' | 'success' = 'error';

  @Output() closeMessage = new EventEmitter<boolean>();

  onCloseError() {
    this.closeMessage.emit(true);
  }
  public get classes(): string[] {
    return [`message-${this.messageType}`, 'message'];
  }
}

<mat-divider *ngIf="index > 0" style="margin-top: 0.5rem"></mat-divider>
<div class="item">
  <div class="label">
    {{ label | titlecase }}
  </div>
  <div data-testid="details" class="details">
    <ng-content></ng-content>
  </div>
</div>
<div class="action-row">
  <button
    mat-stroked-button
    *ngIf="buttons.edit.visible"
    (click)="onEdit()"
    [attr.data-testid]="'edit-' + getDataCyName(label)"
  >
    {{ buttons.edit.label }}
  </button>
  <!-- <span *ngIf="buttons.edit.visible && buttons.remove.visible">|</span> -->
  <button
    *ngIf="buttons.remove.visible"
    mat-button
    (click)="onRemove()"
    [attr.data-testid]="'delete-' + getDataCyName(label)"
  >
    {{ buttons.remove.label }}
  </button>
</div>

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { CustomerAddress } from '../../../customer/customer.model';
import { GarbageCollectionSchedule } from './garbage-collection.model';
import { GarbageCollectionService } from './garbage-collection.service';

export interface GarbageCollectionState {
  schedule: GarbageCollectionSchedule;
  error: { message: string; code: string };
  loading: boolean;
}

@Injectable()
export class GarbageCollectionStore extends ComponentStore<GarbageCollectionState> {
  //read state
  readonly garbageSchedule$ = this.select((state) => state.schedule);
  readonly error$ = this.select((state) => state.error);
  readonly loading$ = this.select((state) => state.loading);

  constructor(private garbageCollectionService: GarbageCollectionService) {
    super({ schedule: null, error: { code: '', message: '' }, loading: true });
  }

  loadGarbageSchedule = this.effect((address$: Observable<CustomerAddress>) =>
    address$.pipe(
      switchMap((address: CustomerAddress) => {
        if (!address) {
          this.patchState({ loading: false });
          return of(null);
        }
        if (!address.isKingston) {
          this.patchState({
            loading: false,
            schedule: null,
            error: { message: 'A Kingston address is required', code: 'NOT_KINGSTON' },
          });
          return of(null);
        }
        this.patchState({ loading: true });
        return this.garbageCollectionService.fetchGarbageSchedule(address).pipe(
          tap({
            next: (schedule) => {
              console.log('returned schedule', schedule);
              if (!schedule.events.length) {
                this.patchState({
                  loading: false,
                  schedule: null,
                  error: { code: 'NO_COLLECTION_FOUND', message: 'No collection information found' },
                });
              } else
                this.patchState({
                  loading: false,
                  schedule: schedule,
                  error: { code: '', message: '' },
                });
            },
            error: (error: { errorCode: string; errorMessage: string }) => {
              if (error.errorCode === 'ERROR_RECOLLECT_NO_EVENTS') {
                this.patchState({
                  loading: false,
                  error: { message: error.errorMessage, code: '204' },
                });
              } else {
                this.patchState({
                  loading: false,
                  error: { message: error.errorMessage, code: 'API_ERROR' },
                });
              }
            },
          }),
          catchError((error) => {
            console.log(error);
            return of(null);
          })
        );
      })
    )
  );
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-notice',
  templateUrl: './dialog-notice.component.html',
  styleUrls: ['./dialog-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogNoticeComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string; title: string }) {}
}

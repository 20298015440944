import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DialogNoticeComponent } from '../../../shared/dialog-notice/dialog-notice.component';
import { MfapApplication } from '../mfap.model';
import { MfapService } from '../mfap.service';
import * as MfapActions from './mfap.actions';

const handleError = (errorRes) => {
  let errorMessage = errorRes.error.errorMessage || 'An unknown error occurred!';

  if (!errorRes.error || !errorRes.error.error) {
    return of(MfapActions.mfapApplicationFailed({ payload: errorMessage }));
  }

  //TODO make custom error message for what can go wrong with loading the customer profiles
  // switch (errorRes.error.error.message) {
  //   case 'EMAIL_EXISTS':
  //     errorMessage = 'This email exists already';
  //     break;
  //   case 'EMAIL_NOT_FOUND':
  //     errorMessage = 'This email does not exist';
  //     break;
  //   case 'INVALID_PASSWORD':
  //     errorMessage = 'Invalid password';
  //     break;
  // }
  return of(MfapActions.mfapApplicationFailed({ payload: errorMessage }));
};

@Injectable()
export class MfapEffects {
  fetchMfapApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.fetchMfapApplication),
      switchMap(() => {
        return this.mfapService.fetchMfap().pipe(
          map((mfapApplication: MfapApplication) => {
            if (mfapApplication) {
              return MfapActions.setMfapApplication({
                payload: mfapApplication,
              });
            } else {
              return MfapActions.mfapNoApplication();
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        );
      })
    )
  );
  createMfapApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.startCreateMfapApplication),
      switchMap((mfapAction) => {
        return this.mfapService.createMfap(mfapAction.payload).pipe(
          map((mfapApplication: MfapApplication) => {
            return MfapActions.createMfapApplicationSuccess({
              payload: mfapApplication,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        );
      })
    )
  );
  renewMfapApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.startRenewMfapApplication),
      switchMap((mfapAction) => {
        return this.mfapService.renewMfap(mfapAction.payload).pipe(
          map((mfapApplication: MfapApplication) => {
            return MfapActions.renewMfapApplicationSuccess({
              payload: mfapApplication,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        );
      })
    )
  );
  applyMfapEhb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.startMfapEhbApplication),
      switchMap(() => {
        return this.mfapService.applyHealth('apply').pipe(
          map((mfapApplication: MfapApplication) => {
            return MfapActions.successMfapEhbApplication({
              payload: mfapApplication,
            });
          }),
          catchError((errorRes) => {
            return of(MfapActions.mfapEhbFailed({ payload: errorRes.error.errorMessage || 'Error - could not apply' }));
          })
        );
      })
    )
  );
  applyMfapPv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.startMfapPvApplication),
      switchMap(() => {
        return this.mfapService.applyPetVoucher('apply').pipe(
          map((mfapApplication: MfapApplication) => {
            return MfapActions.successMfapPvApplication({
              payload: mfapApplication,
            });
          }),
          catchError((errorRes) => {
            return of(MfapActions.mfapPvFailed({ payload: errorRes.error.errorMessage || 'Error - could not apply' }));
          })
        );
      })
    )
  );
  applyMfapSpark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.startMfapSparkApplication),
      switchMap(() => {
        return this.mfapService.applySpark('apply').pipe(
          map((mfapApplication: MfapApplication) => {
            return MfapActions.successMfapSparkApplication({
              payload: mfapApplication,
            });
          }),
          catchError((errorRes) => {
            return of(
              MfapActions.mfapSparkFailed({ payload: errorRes.error.errorMessage || 'Error - could not apply' })
            );
          })
        );
      })
    )
  );
  newLetterMfapEhb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.startMfapEhbNewLetter),
      switchMap(() => {
        return this.mfapService.applyHealth('new-letter').pipe(
          map((mfapApplication: MfapApplication) => {
            return MfapActions.successMfapEhbNewLetter({
              payload: mfapApplication,
            });
          }),
          catchError((errorRes) => {
            return of(
              MfapActions.mfapEhbFailed({ payload: errorRes.error.errorMessage || 'Error could not create new letter' })
            );
          })
        );
      })
    )
  );
  deleteMfapApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfapActions.startDeleteMfapApplication),

      switchMap(() => {
        return this.mfapService.deleteMfap().pipe(
          map(() => {
            return MfapActions.deleteMfapApplicationSuccess();
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        );
      })
    )
  );
  openMfapEhbApplySuccessDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MfapActions.successMfapEhbApplication),
        tap(() => {
          this.dialog.open(DialogNoticeComponent, {
            data: {
              message:
                'Your request for Extended Health Care Benefits has been submitted. You can review your status in the Available Services menu.',
              title: 'Application Complete',
            },
          });
        })
      ),
    { dispatch: false }
  );
  openMfapPvApplySuccessDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MfapActions.successMfapPvApplication),
        tap(() => {
          this.dialog.open(DialogNoticeComponent, {
            data: {
              message:
                'Your Spay and Neuter Voucher request has been submitted. You can review your status in the Available Services menu.',
              title: 'Voucher Request Complete',
            },
          });
        })
      ),
    { dispatch: false }
  );
  openMfapSparkApplySuccessDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MfapActions.successMfapSparkApplication),
        tap(() => {
          this.dialog.open(DialogNoticeComponent, {
            data: {
              message:
                'Your request for SPARK recreation credits has been submitted. You can review your status in the Available Services menu.',
              title: 'SPARK Credit Requested',
            },
          });
        })
      ),
    { dispatch: false }
  );
  mfapPvError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MfapActions.mfapPvFailed),
        tap(() => {
          this.dialog.open(DialogNoticeComponent, {
            data: {
              message:
                'An error has occurred and your Spay and Neuter Voucher request could not be submitted. Try again later.',
              title: 'Spay and Neuter Voucher Error',
            },
          });
        })
      ),
    { dispatch: false }
  );
  mfapEhbError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MfapActions.mfapEhbFailed),
        tap(() => {
          this.dialog.open(DialogNoticeComponent, {
            data: {
              message:
                'An error has occurred and your Extended Health Benefits request could not be submitted. Try again later.',
              title: 'Extended Health Benefits Error',
            },
          });
        })
      ),
    { dispatch: false }
  );
  openMfapEhbNewLetterSuccessDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MfapActions.successMfapEhbNewLetter),
        tap(() => {
          this.dialog.open(DialogNoticeComponent, {
            data: {
              message:
                'Your request for a new letter has been submitted. You can review your status in the Available Services menu.',
              title: 'New Letter Request Complete',
            },
          });
        })
      ),
    { dispatch: false }
  );
  mfapSparkError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MfapActions.mfapSparkFailed),
        tap(() => {
          this.dialog.open(DialogNoticeComponent, {
            data: {
              message:
                'An error has occurred and your request for SPARK recreation credits could not be submitted. Try again later.',
              title: 'SPARK Request Error',
            },
          });
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private mfapService: MfapService, private dialog: MatDialog) {}
}

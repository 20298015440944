import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { CustomerAddress } from '../../../customer/customer.model';
import { GarbageCollectionSchedule } from './garbage-collection.model';

@Injectable({
  providedIn: 'root',
})
export class GarbageCollectionService {
  schedule: GarbageCollectionSchedule = null;

  lastAddressSearched: CustomerAddress = null;
  primaryAddress: CustomerAddress = null;
  constructor(private http: HttpClient) {}

  fetchGarbageSchedule(address: CustomerAddress): Observable<GarbageCollectionSchedule> {
    return this.http.get<GarbageCollectionSchedule>(`${env.serverUrl}/waste/collection/${address.addressId}`).pipe(
      map((schedule) => {
        this.lastAddressSearched = address;
        this.schedule = schedule;
        return schedule;
      }),
      catchError((err) => {
        let errorMessage = 'Address Error';
        if (err.error.errorCode === 'ERROR_ADDRESS_IS_NOT_KINGSTON') errorMessage = 'Kingston Address Required';
        return throwError(() => new Error(errorMessage));
      })
    );
  }
}

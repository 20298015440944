import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { select, Store } from '@ngrx/store';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import * as AuthActions from '../auth/store/auth.actions';
import { CustomerAddress, CustomerPreferences } from '../customer/customer.model';
import * as CustomerAddressActions from '../customer/store/actions/customer-address.actions';
import * as ServiceCardActions from '../service-cards/data-access/store/service-card.actions';
import {
  selectCustomerAddressErrorMessage,
  selectCustomerAddressLoaded,
  selectHasPrimaryAddress,
} from '../customer/store/selectors/customer-address.selector';
import * as fromApp from '../store/app.reducer';

import { selectCustomerPreferences } from '../customer/store/selectors/customer.selector';
import { WidgetSettings } from '../app-settings/app-settings.model';
import { selectAppSettings } from '../app-settings/store/app-settings.selectors';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  loggedIn$: Observable<boolean>;
  addressesLoaded$: Observable<boolean>;
  residentialAddress$: Observable<CustomerAddress>;
  preferences$: Observable<CustomerPreferences>;
  imageSource$: Observable<string>;
  addressError$: Observable<string>;
  widgetSettings$: Observable<WidgetSettings>;
  constructor(private authService: AuthService, private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.widgetSettings$ = this.store.pipe(
      select(selectAppSettings),
      map((settings) => settings.widgets)
    );
    this.addressesLoaded$ = this.store.pipe(select(selectCustomerAddressLoaded));
    this.addressError$ = this.store.pipe(select(selectCustomerAddressErrorMessage));
    this.preferences$ = this.store.pipe(select(selectCustomerPreferences));

    this.loggedIn$ = this.authService.isAuthenticated$.pipe(
      tap((loggedIn) => {
        console.log('logged value', loggedIn);
        if (loggedIn) this.store.dispatch(ServiceCardActions.fetchServiceCardsByCustomer());
        else this.store.dispatch(ServiceCardActions.fetchAllServiceCards());
      }),
      switchMap((loggedIn) => {
        if (loggedIn) {
          return of({ loggedIn, loaded: this.store.pipe(select(selectCustomerAddressLoaded)) });
        }

        return of({ loggedIn, loaded: false });
      }),
      map(({ loggedIn, loaded }) => {
        if (loggedIn && !loaded) {
          this.store.dispatch(CustomerAddressActions.fetchCustomerAddresses());
        }
        return loggedIn;
      })
    );

    this.residentialAddress$ = this.store.pipe(select(selectHasPrimaryAddress));
    this.imageSource$ = this.preferences$.pipe(
      map((preferences) => {
        return preferences.darkMode
          ? './../assets/images/svg/white-city-logo.svg'
          : './../assets/images/svg/blue-city-logo.svg';
      })
    );
  }
  onLogin() {
    this.store.dispatch(AuthActions.login());
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map } from 'rxjs/operators';
import * as ServiceCardActions from './service-card.actions';
import { ServiceCardService } from '../../utils/service-card.service';
import { ServiceCard } from '../service-card.model';
import { of } from 'rxjs';

const handleError = (errorRes: any) => {
  console.log('Error fetching service cards:', errorRes);
  let errorMessage = errorRes?.error?.errorMessage || 'Something went wrong and we could not get the list of services';
  return of(ServiceCardActions.ServiceCardsFailure({ errorMessage: errorMessage }));
};

@Injectable()
export class ServiceCardEffects {
  fetchServiceCardsCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceCardActions.fetchServiceCardsByCustomer),
      switchMap(() => {
        return this.serviceCardService.fetchServiceCardsCustomer().pipe(
          map((serviceCards: ServiceCard[]) => {
            if (serviceCards) {
              return ServiceCardActions.ServiceCardsLoaded({ serviceCards: serviceCards });
            } else {
              return { type: 'DUMMY STATE - No Service Cards Fetched' };
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        );
      })
    )
  );
  fetchServiceCardsAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceCardActions.fetchAllServiceCards),
      switchMap(() => {
        return this.serviceCardService.fetchServiceCardsAll().pipe(
          map((serviceCards: ServiceCard[]) => {
            if (serviceCards) {
              return ServiceCardActions.ServiceCardsLoaded({ serviceCards: serviceCards });
            } else {
              return { type: 'DUMMY STATE - No Service Cards Fetched' };
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private serviceCardService: ServiceCardService) {}
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';

import { ServiceRequest } from '../../data-access/service-request.model';

@Component({
  selector: 'app-service-request-list',
  templateUrl: './service-request-list.component.html',
  styleUrls: ['./service-request-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class ServiceRequestListComponent {
  @Input() requests: ServiceRequest[] = [];
  @Input() loading = false;
  @Input() limit = 1;
}

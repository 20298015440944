<ng-container *ngIf="{ widgetSettings: widgetSettings$ | async } as vm">
  <div *ngIf="vm.widgetSettings.visible" class="widget" data-testid="district-widget">
    <h2>District details</h2>
    <div class="loading" *ngIf="!addressesLoaded && loggedIn && !addressError; else loaded">
      <div style="width: 50%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
      </div>

      <div style="width: 40%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
      </div>

      <div style="width: 50%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
      </div>
    </div>
    <ng-template #loaded>
      <app-district-details
        [loading]="!addressesLoaded"
        [districtData]="{
          electoralDistrictName: address?.electoralDistrictName,
          electoralDistrictId: address?.electoralDistrictId,
          councillorFirstName: address?.councillorFirstName,
          councillorLastName: address?.councillorLastName,
          councillorWebsiteUrl: address?.councillorWebsiteUrl
        }"
      ></app-district-details>

      <div *ngIf="!address && addressesLoaded && !showDescription">
        <p>Add a Kingston address to view your district details.</p>
        <app-add-residential-address />
      </div>
    </ng-template>
    <div *ngIf="showDescription && (!loggedIn || !address || (address && !address?.electoralDistrictName))">
      Keep up to date on your neighbourhood's electoral district and councillor details.
    </div>
    <div *ngIf="address && !address?.electoralDistrictName && !showDescription">
      <app-message-display messageType="info" [showCloseButton]="false">
        <span *ngIf="address.isKingston; else notKingston">
          No district details found for {{ address?.streetAddress }}.
        </span>
        <ng-template #notKingston> A Kingston address is required for district details </ng-template>
      </app-message-display>
      <button
        mat-raised-button
        color="accent"
        data-testid="edit-address"
        (click)="onEdit(address)"
        style="margin: 0.5rem 0 0.5rem 0"
      >
        Edit address
      </button>
    </div>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as introJs from 'intro.js/intro.js';
import * as fromApp from '../../store/app.reducer';
import * as CustomerActions from '../../customer/store/actions/customer.actions';
import { CustomerPreferences } from '../../customer/customer.model';

@Injectable({
  providedIn: 'root',
})
export class IntrojsService {
  introJS = null;

  anonymousWalkthroughMobile() {
    console.log('Starting introJS demo');
    this.introJS = introJs();
    this.introJS.start();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        nextLabel: 'Next',
        prevLabel: 'Previous',
        doneLabel: 'Done',
        steps: [
          {
            title: 'Welcome',
            element: '#introStep',
            intro: 'MyKingston is your online portal to access City of Kingston services.',
          },
          {
            title: 'Navigation',
            element: '#menu-button',
            position: 'right',
            intro:
              'The menu let you access things like your home dashboard and useful information about your neighbourhood and address.',
          },

          {
            title: 'Profile',
            element: '#profile',
            intro: 'The profile area lets you manage your login information.',
          },
        ],
      })
      .onchange((targetElementId) => {
        console.log(targetElementId.id);
        if (targetElementId.id === 'menu-button' || targetElementId.id === 'profile') {
          this.toggleMenu();
        }
      })
      .start();
    this.introJS.onexit(() => {
      this.setPreferencesTutorialShown();
    });
  }

  anonymousWalkthrough() {
    console.log('Starting introJS demo');
    this.introJS = introJs();
    this.introJS.start();
    this.introJS
      .setOptions({
        nextLabel: 'Next',
        prevLabel: 'Previous',
        doneLabel: 'Done',
        tooltipClass: 'customTooltip',
        steps: [
          {
            title: 'Welcome',
            element: '#introStep',
            intro: 'MyKingston is your online portal to access City of Kingston services.',
          },
          {
            title: 'Home',
            element: '#dashboard',
            intro: 'The home page provides a personalized overview of popular services.',
            tooltipPosition: 'right',
          },
          {
            title: 'My profile',
            element: '#my-profile',
            intro: 'Manage your contact information like your address and phone numbers.',
          },

          {
            title: 'Residence',
            element: '#nav-residence',
            intro:
              'The residence page uses your address to provide information specific to your location like your garbage collection schedule.',
          },
          // {
          //   title: 'Neighbourhood',
          //   element: '#nav-neighbourhood',
          //   intro: 'The neighbourhood page shows you City services around your residence.',
          // },
          {
            title: 'Services',
            element: '#services',
            intro: 'The services page gives you a searchable list of things you can do online through MyKingston.',
          },
          {
            title: 'Profile',
            element: '#profile',
            intro: 'The profile area lets you manage your login information.',
          },
        ],
      })
      .start();
    this.introJS.onexit(() => {
      this.setPreferencesTutorialShown();
    });
  }
  setPreferencesTutorialShown() {
    let newPreferences: CustomerPreferences = { shownTutorial: true };
    this.store.dispatch(CustomerActions.patchCustomerPreferences({ payload: newPreferences }));
  }

  toggleMenu() {
    let element: HTMLElement = document.getElementById('menu-button') as HTMLElement;
    console.log(element);
    element.click();
  }

  constructor(private store: Store<fromApp.AppState>) {}
}

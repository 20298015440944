<ul *ngIf="garbageServices">
  <li *ngFor="let service of garbageServices">
    <div class="service-item">
      <span *ngIf="service.type === 'greybox' || service.type === 'bluebox'">
        <mat-icon [style.color]="service.type === 'greybox' ? '#999999' : '#008c9e'">recycling</mat-icon>
      </span>
      <span *ngIf="service.type === 'greenbin'">
        <mat-icon class="greenbin">compost</mat-icon>
      </span>
      <span *ngIf="service.type === 'garbage' || service.type === 'twogarbage'">
        <mat-icon>delete</mat-icon>
      </span>
      <span *ngIf="service.type === 'Battery_Collection'">
        <mat-icon>battery_charging_full</mat-icon>
      </span>
      <span *ngIf="service.type === 'leafweek'" class="leafweek">
        <mat-icon>spa</mat-icon>
      </span>
      <span *ngIf="service.type === 'brushweek'">
        <mat-icon>park</mat-icon>
      </span>
      <div class="service-label">
        {{ service.label }}
      </div>
    </div>
  </li>
</ul>

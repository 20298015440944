import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCheckingAuthState } from 'src/app/auth/store/auth.selectors';
import { AuthenticationService } from '../../auth/auth.service';
import * as AuthActions from '../../auth/store/auth.actions';
import { Customer, CustomerPreferences } from '../../customer/customer.model';
import { selectCurrentCustomer, selectCustomerPreferences } from '../../customer/store/selectors/customer.selector';
import * as fromApp from '../../store/app.reducer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  customerProfile$: Observable<Customer>;
  customerName$: Observable<string>; //for UI to display customer data
  customerPreferences$: Observable<CustomerPreferences>;
  authChecking$: Observable<boolean>;

  @Input() mode = 'over';
  @Input() startDark = false;
  @Output() menuToggle: EventEmitter<any> = new EventEmitter();
  @Output() readonly darkModeSwitched = new EventEmitter<boolean>();

  isLoggedIn$: Observable<boolean>;

  constructor(private store: Store<fromApp.AppState>, private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.customerName$ = this.authService.user$;
    this.customerProfile$ = this.store.pipe(select(selectCurrentCustomer));
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.customerPreferences$ = this.store.pipe(select(selectCustomerPreferences));
    this.authChecking$ = this.store.pipe(select(selectCheckingAuthState));
  }

  onLogout() {
    this.store.dispatch(AuthActions.logout());
  }
  onLogin() {
    this.store.dispatch(AuthActions.login());
  }

  onDarkModeSwitched({ checked }: MatSlideToggleChange) {
    this.darkModeSwitched.emit(checked);
  }

  onToggleMenu() {
    this.menuToggle.emit(null);
  }

  onToggleDarkMode(darkModeSlider: MatSlideToggle) {
    darkModeSlider.toggle();
    this.darkModeSwitched.emit(darkModeSlider.checked);
  }
}

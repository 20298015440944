import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { selectAppSettings } from '../../app-settings/store/app-settings.selectors';
import { BaseWidgetSettings } from '../../app-settings/app-settings.model';
import { CustomerAddressComponent } from '../../customer/customer-address-list/customer-address/customer-address.component';
import { CustomerAddress } from '../../customer/customer.model';
import * as CustomerAddressActions from '../../customer/store/actions/customer-address.actions';
import { SharedModule } from '../../shared/shared.module';
import * as fromApp from '../../store/app.reducer';
import { DistrictDetailsComponent } from './ui/district-details/district-details.component';

@Component({
  selector: 'app-district-shell',
  templateUrl: './district-shell.component.html',
  styleUrls: ['./district-shell.component.scss'],
  standalone: true,
  imports: [SharedModule, DistrictDetailsComponent],
})
export class DistrictShellComponent implements OnInit {
  @Input() loggedIn = true;
  @Input() address: CustomerAddress;
  @Input() addressesLoaded = true;
  @Input() addressError = true;
  @Input() showDescription = false;

  widgetSettings$: Observable<BaseWidgetSettings>;
  constructor(private store: Store<fromApp.AppState>, private dialog: MatDialog) {}
  ngOnInit(): void {
    this.widgetSettings$ = this.store.pipe(
      select(selectAppSettings),
      map((settings) => settings.widgets.district)
    );
  }
  onEdit(address: CustomerAddress) {
    this.store.dispatch(CustomerAddressActions.displayCustomerAddressDialog());
    this.dialog.open(CustomerAddressComponent, {
      data: { mode: 'edit', filter: 'residential', address: address },
      maxWidth: 400,
    });
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountCompleteGuard } from './auth/account-complete.guard';
import { LogoutComponent } from './auth/logout/logout.component';
import { LogoutGuard } from './auth/logout/logout.guard';
import { HomeComponent } from './home/home.component';
import { TermsOfServiceComponent } from './shared/terms-of-service/terms-of-service.component';

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('./account-error/account-error.module').then((m) => m.AccountErrorModule),
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [LogoutGuard],
    title: 'Logout - MyKingston',
  },

  {
    path: 'services',
    loadChildren: () => import('./service-cards/service-card.module').then((m) => m.ServiceCardModule),
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
    title: 'Terms - MyKingston',
  },
  {
    path: 'home',
    title: 'Home - MyKingston',
    component: HomeComponent,
    //loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'address',
    loadChildren: () =>
      import('./widgets/residential-property-info/residential-property.module').then(
        (m) => m.ResidentialPropertyModule
      ),
  },
  // {
  //   path: 'map',
  //   loadChildren: () => import('./widgets/poi-map/poi-map.module').then((m) => m.PoiMapModule),
  // },
  {
    path: 'register',
    canActivate: [AccountCompleteGuard],
    loadChildren: () => import('./account-register/account-register.module').then((m) => m.AccountRegisterModule),
  },
  {
    path: '',
    canActivateChild: [AccountCompleteGuard],
    children: [
      {
        path: 'profile',

        loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'mfap',
        loadChildren: () => import('./services/mfap/mfap.module').then((m) => m.MfapModule),
      },
      {
        path: '',
        component: HomeComponent,
        //loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        // canActivate: [AuthErrorGuard],
        title: 'Home - MyKingston',
      },

      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

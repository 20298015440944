import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DistrictData } from '../../data-access/district.model';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-district-details',
  templateUrl: './district-details.component.html',
  styleUrls: ['./district-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class DistrictDetailsComponent {
  @Input() districtData: DistrictData;
  @Input() loading = false;
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerPreferences } from '../customer/customer.model';
import * as CustomerActions from '../customer/store/actions/customer.actions';
import * as fromApp from '../store/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(private store: Store<fromApp.AppState>) {}

  /**
   * This method is called by the init module as a useFactory
   * @returns Observable of the customer's preferences from local storage. Null if local storage is empty.
   */
  loadPreferences(): Observable<CustomerPreferences> {
    let preferences: CustomerPreferences = { darkMode: false };
    const localPreferences = this.getLocalStoragePreferences();

    if (localPreferences) {
      preferences = { ...localPreferences };
    } else {
      preferences.darkMode = this.getBrowserPrefersDarkMode();
    }

    return of(preferences).pipe(
      tap((preferences) => {
        this.store.dispatch(CustomerActions.setCustomerPreferences({ payload: preferences }));
      })
    );
  }

  getLocalStoragePreferences(): CustomerPreferences {
    return JSON.parse(localStorage.getItem('preferences'));
  }

  getBrowserPrefersDarkMode(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
}

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccountCompleteGuard  {
  constructor(private router: Router, private as: AuthenticationService) {}
  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //console.log('Account guard child');
    //console.log('Parameters: ', route);
    return this.as.getUserSilently().pipe(
      map((user) => {
        //console.log(user);
        if (user) {
          if (user?.['http://mykingston/profileComplete']) return true;
          else {
            //the user's profile is not complete, navigate to /register
            this.router.navigate(['/register']);
            return false;
          }
        } else {
          return true;
        }
      })
    );
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //--This guard is used for anyone trying to access the /register route. Access is only granted if the user
    // has an account but the account is not complete. The auth0 profileComplete field that will account for
    // a verified email and any additional fields required by MyKingston onboarding process.

    return this.as.getUserSilently().pipe(
      map((user) => {
        if (!user) {
          //there is no logged in user, don't allow navigation to /register
          this.router.navigate(['']);
          return false;
        } else {
          if (user?.['http://mykingston/profileComplete']) {
            //the users profile is already complete, don't allow navigation to /register
            this.router.navigate(['']);
            return false;
          }
          return true;
        }
      })
    );

    // );
  }
}

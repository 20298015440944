import { Component, NgZone, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, map, merge, throttleTime } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../store/auth.actions';
import { environment as env } from '../../../environments/environment';

@Component({
  selector: 'app-activity-detection',
  template: ` <ng-container *ngIf="{ activity: activityObservable$ | async }"></ng-container> `,
  styleUrls: ['./activity-detection.component.scss'],
})
export class ActivityDetectionComponent implements OnInit {
  public inactivityTimerEvent: Array<any>[] = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup'],
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
  ];
  mergedObservable$: Observable<any>;
  activityObservable$: Observable<any>;

  constructor(private _ngZone: NgZone, private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    let observableActivityArray$: Observable<any>[] = [];
    this.inactivityTimerEvent.forEach((x) => {
      observableActivityArray$.push(fromEvent(x[0], x[1]));
    });

    //merge all the window/document events into 1 observable
    this.mergedObservable$ = merge(...observableActivityArray$);
    this.createActivityObservable();
  }
  createActivityObservable(): void {
    //run the code outside the angular zone to stop endless change detection
    this._ngZone.runOutsideAngular(() => {
      this.activityObservable$ = this.mergedObservable$.pipe(
        throttleTime(env.activityThrottleTimeMilliseconds),
        map(() => {
          console.log('activity detected');
          this.store.dispatch(AuthActions.extendLogoutTimer());
        })
      );
    });
  }
}

<div *ngIf="!hasStreetNumber" class="warning-message">
  <app-message-display [showCloseButton]="false" messageType="info">
    Address must have a street number.
  </app-message-display>
</div>

<mat-form-field color="accent" class="full-width" appearance="outline" data-testid="streetAddressAuto">
  <mat-label>Search for your address *</mat-label>
  <input
    data-testid="Address-search"
    matInput
    autocomplete="off"
    maxlength="100"
    #addressSearchField
    aria-label="Address search"
    type="text"
    placeholder="ex. 216 Ontario St"
    [ngModel]="address.streetAddress"
    name="address"
  />
  <mat-error align="end">Search for an address </mat-error>
  <span matSuffix><mat-icon>search</mat-icon></span>
</mat-form-field>

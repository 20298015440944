import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CustomerFamilyMember } from '../../../app/customer/customer.model';
import { environment as env } from '../../../environments/environment';
import { MfapApplication } from './mfap.model';

@Injectable({ providedIn: 'root' })
export class MfapService {
  constructor(private http: HttpClient) {}
  applyHealth(requestType: string): Observable<MfapApplication> {
    return this.http.post<MfapApplication>(`${env.serverUrl}/customer/mfap/services`, {
      service: 'EHB',
      request: requestType,
    });
  }

  applyPetVoucher(requestType: string): Observable<MfapApplication> {
    return this.http.post<MfapApplication>(`${env.serverUrl}/customer/mfap/services`, {
      service: 'PV',
      request: requestType,
    });
  }
  applySpark(requestType: string): Observable<MfapApplication> {
    return this.http.post<MfapApplication>(`${env.serverUrl}/customer/mfap/services`, {
      service: 'SPARK',
      request: requestType,
    });
  }

  mfapThreshold = {
    0: 31455, //1 person household
    1: 44484, //2 person household
    2: 54481, //etc
    3: 62910,
    4: 70335,
    5: 77048,
    6: 83222,
    7: 88967,
    8: 94364,
    9: 99469,
  };

  checkMfapEligibility(income: number, customerFamily: CustomerFamilyMember[]) {
    const maxIncome = this.mfapThreshold[customerFamily.length];

    return income > maxIncome ? false : true;
  }

  //TODO remove array index when API returns singular.
  fetchMfap(): Observable<MfapApplication> {
    return this.http.get<MfapApplication>(`${env.serverUrl}/customer/mfap`).pipe(
      map((mfap) => {
        return mfap;
      })
    );
  }

  createMfap(mfap: MfapApplication): Observable<MfapApplication> {
    return this.http.post<MfapApplication>(`${env.serverUrl}/customer/mfap`, mfap);
  }
  /**
   * This method will renew the customer's mfap profile
   * @param mfap the mfap profile to renew
   * @returns an Observable of the updated copy of the customer's mfap profile
   */
  renewMfap(mfap: MfapApplication): Observable<MfapApplication> {
    return this.http.post<MfapApplication>(`${env.serverUrl}/customer/mfap/renew`, mfap);
  }

  deleteMfap(): Observable<any> {
    return this.http.delete<any>(`${env.serverUrl}/customer/mfap`);
  }
}

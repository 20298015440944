import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>) {}
  @Output() deleteClicked: EventEmitter<void> = new EventEmitter<void>();
  loading: boolean = false;

  onNoClick() {
    this.dialogRef.close();
  }
  onDeleteClick() {
    if (this.dialogRef) {
      this.loading = true;
      this.deleteClicked.emit();
    }
  }
}

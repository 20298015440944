<div *ngIf="loading; else loaded">
  <div style="width: 50%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
  </div>

  <div style="width: 40%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
  </div>

  <div style="width: 60%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }"></ngx-skeleton-loader>
  </div>
</div>
<ng-template #loaded>
  <div class="table" *ngIf="districtData?.electoralDistrictName">
    <div class="tr">
      <div class="td label">Electoral district:</div>
      <div class="td">{{ districtData?.electoralDistrictName }}</div>
    </div>
    <div class="tr">
      <div class="td label">District #:</div>
      <div class="td">{{ districtData?.electoralDistrictId }}</div>
    </div>
    <div class="tr">
      <div class="td label">Councillor:</div>
      <div data-testid="councillor" class="td">
        <a target="_blank" rel="noopener" [href]="districtData?.councillorWebsiteUrl"
          >{{ districtData?.councillorFirstName }} {{ districtData?.councillorLastName }}</a
        >
      </div>
    </div>
  </div>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ServiceCardListComponent } from './service-card-list.component';
import { ServiceCardComponent } from './ui/service-card/service-card.component';

const routes: Routes = [
  {
    path: '',
    title: 'Services - MyKingston ',
    component: ServiceCardListComponent,
  },
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ServiceCardListComponent, ServiceCardComponent],
  exports: [ServiceCardListComponent],
})
export class ServiceCardModule {}

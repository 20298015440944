<div [ngClass]="classes">
  <div class="text">
    <mat-icon class="icon" *ngIf="messageType === 'info'">info</mat-icon>
    <mat-icon class="icon" *ngIf="messageType === 'success'">check_circle</mat-icon>
    <mat-icon class="icon" *ngIf="messageType === 'error'">warning</mat-icon>
    <p style="margin: 0">
      {{ message }}
      <ng-content></ng-content>
    </p>
  </div>
  <button
    *ngIf="showCloseButton"
    mat-icon-button
    (click)="onCloseError()"
    data-testid="close-error"
    aria-label="Close message"
  >
    <mat-icon>highlight_off</mat-icon>
  </button>
</div>
